import React, { useEffect, useState } from 'react';
import { InfoPanel, MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';
import ListPagination from '../../components/ListPagination';
import SettingsItemAuction from '../../components/SettingsItemAuction';
import SettingsSideBar from '../../components/SettingsSideBar';
import Auction from '../../interfaces/Auction';
import { getCustomerAuction } from '../../services/auction';

const CustomerAuctions: React.FC = () => {
    const [auctions, setAuctions] = useState([] as Auction[])
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadAuctions();
    }, [])

    useEffect(() => {
        loadAuctions();
    }, [currentPage])

    async function loadAuctions () {
        setLoading(true);
        try {
            const { data, count } = await getCustomerAuction({ page: currentPage, limit: 5 });
            setAuctions(data);
            setCount(count || 0);
        } catch (error) {
            console.log("Error")
        }
        setLoading(false);
        
    }

    async function onPaginationClick(page: number) {
        setCurrentPage(page);
        loadAuctions();
    }
    
  return (
    <MainContainer>
        <MainContent>
            <SettingsSideBar />
            <InfoPanel>
                <h1>Compras</h1>
                <h2>Leilões [{count}]</h2>

                <div>
                {
                    loading ? <GifLoading /> :
                    auctions.length > 0 ?
                    <>
                        {auctions.map(auc => (
                            <SettingsItemAuction auction={auc} key={auc.id} />
                        ))}

                        <ListPagination
                            initialPage={currentPage}
                            pageClick={onPaginationClick}
                            totalRecords={count}
                        />
                    </>
                    : <p>Nenhum leilão encontrado</p>
                }
                    

                </div>
            </InfoPanel>
        </MainContent>
    </MainContainer>)
}

export default CustomerAuctions;