import React, { useState, FormEvent } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';

import LogoImg from '../../assets/images/logo.svg';
import { Header, Logo, LogoImage, Links, SearchForm, SearchInput, HeaderLink, UlLinks } from './style';

import Hamburger from './Hamburger';
import { useAuth } from '../../contexts/auth';

import queryString from 'query-string';
import VenatuButton from '../VenatuButton';

const PageHeader : React.FC = () => {
    const { categoryId, query } = queryString.parse(useLocation().search);
    const { signed, user, signOut } = useAuth();
    const [search, setSearch] = useState(query || '');
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const active = {
        paddingBottom: '0.1rem',
        borderBottom: '2px solid var(--color-secondary)'
    }

    function handleSignOut() {
        signOut();
        history.push('/')
    }

    function handleSearch(e: FormEvent) {
        e.preventDefault();
        let qs = '?';

        if (categoryId) {
            qs = qs + 'categoryId=' + categoryId + '&';
        }

        qs = qs+'query=' + search;
        history.push(`/comprar${qs}`)
    }

    return (
        <Header>
            <Logo>
                <Link to="/">
                    <LogoImage src={LogoImg} alt="Venatu"  />
                </Link>
            </Logo>
            <SearchForm onSubmit={handleSearch}>
                <SearchInput 
                    placeholder="O que deseja comprar hoje?" 
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />

                <VenatuButton type="submit" txtColor="#FFF">
                    Prosseguir
                </VenatuButton>
            </SearchForm>
            <Links>
                <UlLinks open={open}>
                    {
                        signed ? <>
                            <HeaderLink>
                                <NavLink to="/perfil" activeStyle={active}>{user?.username}</NavLink>
                            </HeaderLink>
                            <HeaderLink>
                                <NavLink to="/comprar" activeStyle={active}>Comprar</NavLink>
                            </HeaderLink>
                            <HeaderLink>
                                <NavLink to="/vender" activeStyle={active}>Vender</NavLink>
                            </HeaderLink>
                            <HeaderLink onClick={handleSignOut}>
                                <Link to="#">
                                    Sair
                                </Link>
                            </HeaderLink>    
                        </> : <>
                            <HeaderLink><NavLink to="/login" activeStyle={active}>Acessar</NavLink></HeaderLink>    
                            <HeaderLink><NavLink to="/register" activeStyle={active}>Cadastrar</NavLink></HeaderLink>    
                        </>
                    }
                    
                </UlLinks>
                <Hamburger open={open} setOpen={setOpen} />
            </Links>
        </Header>
    )
}

export default PageHeader