import React from 'react';
import "./style.tsx";
import {Footer, FooterTop,FooterBot} from './style';
import { Link } from 'react-router-dom';

const PageFooter :React.FC =() => {
    return (
        <Footer>
            <FooterTop>
                <p>Ajuda</p>
                <Link to="/termos-e-condicoes">Termos e condições</Link>
                <Link to="/politicas-de-privacidade">Políticas de Privacidade</Link>
                <p>Contato</p>
            </FooterTop>
            <FooterBot>
                Copyright 2020 - venatu.com.br LTDA 
            </FooterBot>
        </Footer>
    )
}

export default PageFooter;