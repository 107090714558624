import React, { useEffect, useState } from 'react';
import { InfoPanel, MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';
import SettingsItemPurchase from '../../components/SettingsItemPurchase';
import SettingsSideBar from '../../components/SettingsSideBar';
import Purchase from '../../interfaces/Purchase';
import { getFinishedBySeller } from '../../services/purchase' 

const SellerFinished: React.FC = () => {
    const [finished, setFinished] = useState([] as Purchase[])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        initFinish()
    }, [])

    const initFinish = async () => {
        setLoading(true)
        try {
            const finisheds = await getFinishedBySeller();
            setFinished(finisheds);
        } catch (error) {
            console.log("Error")
        }
        setLoading(false)
    }

    return (
        <MainContainer>
            <MainContent>
                <SettingsSideBar />
                <InfoPanel>
                    <h1>Vendas</h1>
                    <h2>Finalizados</h2>

                    <h3>
                        Vendas finalizadas
                    </h3>
                    
                    {
                        loading ? <GifLoading /> :
                        finished.length > 0 ?
                            finished.map(purchase => <SettingsItemPurchase key={purchase.id} purchase={purchase} />)
                        :
                            <p>Nenhuma venda finalizado.</p>
                    }
                </InfoPanel>
            </MainContent>
        </MainContainer>    
    );
}

export default SellerFinished;