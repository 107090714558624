import React, { useEffect, useState } from 'react';
import Bid from '../../../interfaces/Bid';
import Offer from '../../../interfaces/Offer';
import { formatDate } from '../../../util/formatDate';
import CurrencyText from '../../CurrencyText';
import { CurrentPriceLabel, HourLabel, LastBidLabel, TitleRow } from '../styles';

interface BidsRowProps {
    bid: Bid,
    offer?: Offer
}

const BidsRow: React.FC<BidsRowProps> = ({
    bid,
    offer
}) => {

    const [isMyOffer, setIsMyOffer] = useState(offer?.id === bid.offerId)

    useEffect(() => {
        setIsMyOffer(offer?.id === bid.offerId);
    }, [offer, bid.offerId]);

    return (
        <TitleRow isMyOffer={isMyOffer} >
            <HourLabel>{formatDate(bid.createdAt)}</HourLabel>
            <CurrentPriceLabel>
                <CurrencyText value={bid.currentPrice} />
                    {
                    isMyOffer && 
                        <small>Seu lance</small>
                    }
                </CurrentPriceLabel>
                <LastBidLabel><CurrencyText value={bid.bid} /></LastBidLabel>
        </TitleRow>
  )
}

export default BidsRow;