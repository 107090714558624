import styled from 'styled-components';

export const AuctionSection = styled.section`
  margin-top: 4rem;
  width: 50%;
  & > * {
    margin-top: 1rem;
  }
`;

export const OfferSection = styled.section`
  margin-top: 4rem;
  width: 50%;
`;

export const SimpleUserView = styled.div`
  display: flex;
  flex-direction: row;

  & > * + * {
    margin-left: 1rem;
  }

  & > div > strong {
    color: var(--color-strong-gray);
  }

  & > div > p {
    color: var(--color-gray);
    font-weight: bold;
  }
`

export const Avatar = styled.img`
  width: 6.25rem;
  height: 6.25rem;
`