import React ,{ useState, useEffect } from 'react';
import "./style.tsx";


import { Categories, CategoriesLayer, CategoryDiv, CategoryImage, CategoryTitle } from './style';
import { Link } from 'react-router-dom';
import { RowPanel, TitleInRowPanel } from '../../assets/GlobalStyles';
import { getCategories } from '../../services/category';
import GifLoading from '../GifLoading';



interface ItemCategory {
    id: number;
    title: string;
    icon: string
}
const AvailableCategories :React.FC = () => {
    const [categories, setCategories] = useState([] as ItemCategory[]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async function () {
            setLoading(true)
            const categories = await getCategories();
            setCategories(categories);
            setLoading(false)
        })()
    }, []);

  

    return (

        <Categories>
            <RowPanel>
                <TitleInRowPanel>Categorias disponíveis</TitleInRowPanel>
                <CategoriesLayer>
                {

                    loading ?
                        <GifLoading />
                    : categories ? 
                        categories.map((repo: ItemCategory) => (
                            <CategoryDiv key={repo.title}>
                                <Link to={`/comprar?categoryId=${repo.id}`} >
                                    <CategoryImage src= {repo.icon}></CategoryImage>
                                    <CategoryTitle >{repo.title}</CategoryTitle> 
                                </Link>
                            </CategoryDiv>
                        ))
                    :
                        <CategoryDiv>
                            <Link to="#">
                                <CategoryTitle >Nenhuma categoria disponível</CategoryTitle> 
                            </Link>
                        </CategoryDiv>               
                }
                </CategoriesLayer>
            </RowPanel>
        </Categories>
    )
}

export default AvailableCategories;