import React from 'react';
import { ItemProductContainer, ItemProductImage, ItemProductInfo, ItemProductTitle, ItemProductShowcaseMediaPrice, ItemProductPrice } from './style';
import CurrencyText from '../CurrencyText';
import { useHistory } from 'react-router-dom';
import ProductState from '../ProductState';
import { formatProductToRouteParams } from '../../util/formatProductToRouteParams';

interface ItemProductProps {
    product: {
        id: number,
        title: string,
        imageUrl: string ,
        showcasePrice: number,
        state: "NEW" | "USED"
    },
    showcaseText?: string
}
const ItemProduct : React.FC<ItemProductProps> = ({ product, showcaseText }) => {

    const history = useHistory();

    function navigateToProductDetail() {
        history.push(`/products/${formatProductToRouteParams(product, product.state)}`)
    }

    return (
         <ItemProductContainer onClick={navigateToProductDetail}>
            <ItemProductImage src={`${product.imageUrl}`} />
            <ItemProductInfo>
                <ItemProductTitle>
                    {product.title}
                    <div>
                        <ProductState state={product.state} />
                    </div>
                </ItemProductTitle>
    
                <ItemProductShowcaseMediaPrice>
                    {showcaseText || `Média do preço de vitrine`}
                </ItemProductShowcaseMediaPrice>
                <ItemProductPrice>
                    <CurrencyText value={product.showcasePrice} />
                </ItemProductPrice>
            </ItemProductInfo>
        </ItemProductContainer>
    )
}

export default ItemProduct;