import React from 'react';
import Countdown from 'react-countdown';
import { useHistory } from 'react-router-dom';


interface VenatuCountDownProps {
    date: Date
}

const VenatuCountDown: React.FC<VenatuCountDownProps> = ({
    children,
    date
}) => {
  return (
    <Countdown date={date}>
        <p>Recarregue a página</p>
    </Countdown>
  )
}

export default VenatuCountDown;