import api from '../api';

export const getCategories = async () => {
    try {
        const { data } = await api.get(`categories`);
        return data; 
    } catch (err) {
        console.log('===    ERROR ON getCategories     ===')
        console.log(err);
    }
}

export const getProductsByCategory = async (categoryId : number, limit = 50) => {
    try {
        const params = {
            categoryId,
            limit
        }
        const { data } = await api.get(`products`, { params });
        return data; 
    } catch (err) {
        console.log('===    ERROR ON getProductsByCategory     ===')
        console.log(err);
    }
}