import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';
import { PriceInfoSection, PurchaseIdText, PurchaseInfoSection,  PurchaseInfoTitle, PurchaseEconomyText, PurchaseTitleText,  PaymentForm, PurchaseMainAddressText, PurchaseSecondaryAddressText, PurchaseInternLink, HistoryTransaction } from './styles';
import { findById } from '../../services/purchase';
import Purchase from '../../interfaces/Purchase';
import CurrencyText from '../../components/CurrencyText';
import { formatProductToRouteParams } from '../../util/formatProductToRouteParams';
import TransactionItem from '../../components/TransactionItem';

interface PurchaseAuctionParams {
    id: string,
}

const PurchaseDetail = () => {
    const { id } = useParams<PurchaseAuctionParams>();
    const [purchase, setPurchase] = useState<Purchase>({} as Purchase)
    const [loading, setLoading] = useState(false);
    
    
    useEffect(() => {
        initPurchase()
    }, [id])

    async function initPurchase () {
        setLoading(true);
        try {
            const data = await findById(Number(id));
            setPurchase(data);
        } catch (error) {
            console.log("Error")
        }
        setLoading(false);
    }

    return (
        <MainContainer>
            {
                loading ? <GifLoading /> : 
            <MainContent>
                <PurchaseInfoSection>
                    <PurchaseIdText>Compra #{purchase.id}</PurchaseIdText>

                    <div>
                        <PurchaseTitleText>
                            {purchase.offer?.auction?.product.title}
                            <Link to={`/products/${formatProductToRouteParams(purchase.offer?.auction?.product, purchase.offer?.auction?.state)}`}>
                                Ver detalhes
                            </Link>
                        </PurchaseTitleText>
                        <PurchaseIdText>Valor alcançado: <CurrencyText value={purchase.price} /></PurchaseIdText>
                        <PurchaseEconomyText>Diferença do valor inicial: <CurrencyText value={purchase.offer?.auction?.initialBid - purchase.price} /></PurchaseEconomyText>
                    </div>

                    <div>
                        <PurchaseInfoTitle>Comprado por</PurchaseInfoTitle>
                        <PurchaseMainAddressText>
                            {purchase.offer?.auction?.buyer?.first_name} {purchase.offer?.auction?.buyer?.last_name}
                        </PurchaseMainAddressText>
                        <PurchaseSecondaryAddressText>
                            <p>Telefone: {purchase.offer?.auction?.buyer?.user?.phone}</p>
                            <p>Email: {purchase.offer?.auction?.buyer?.user?.email}</p>
                        </PurchaseSecondaryAddressText>
                    </div>

                    <PurchaseInfoTitle>Endereço de entrega</PurchaseInfoTitle>

                    <div>
                        <PurchaseMainAddressText>
                            {purchase.street}, 
                            nº {purchase.number}
                        </PurchaseMainAddressText>
                        <PurchaseSecondaryAddressText>
                            {
                            `${purchase.complement && (purchase.complement + ' - ')}
                            ${purchase.neighbor}, 
                            ${purchase.city}/${purchase.uf}.
                            CEP: ${purchase.zipcode}`
                            }
                        </PurchaseSecondaryAddressText>
                    </div>

                    {
                        purchase.transactions && purchase.transactions.length > 0 ?
                            <>
                            <PurchaseInfoTitle>Histórico de transações</PurchaseInfoTitle>

                            <HistoryTransaction>
                                {
                                    purchase.transactions.map(transaction => (
                                        <TransactionItem
                                            key={transaction.id}
                                            transaction={transaction}
                                        />
                                    ))
                                }
                            </HistoryTransaction>
                            </>
                        :
                        <>
                            <PurchaseInfoTitle>Histórico de transações</PurchaseInfoTitle>
                            <div>Aguardando pagamento</div>
                        </>
                    }
            </PurchaseInfoSection>
            </MainContent>
            }
        </MainContainer>
    )
}

export default PurchaseDetail