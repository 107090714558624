import React, { FormEvent, useState, ChangeEvent, useEffect } from 'react';
import InputMask from 'react-input-mask';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { register } from '../../../services/auth';
import GifLoading from '../../../components/GifLoading';
import { ModalContent } from '../../../components/ProductMainView/styles';
import { MainContainer } from '../../../assets/GlobalStyles';
import { getAddressInfoByCep } from '../../../services/correios/service'
import VenatuButton from '../../../components/VenatuButton';
import { Form, Row, RegisterInput, RegisterSelect, DontKnowZipCode, DivCepLoading } from './style';
import { initCitys, initUfs, IBGEEstado } from '../../../services/ibge/service';
import { isValidCPF } from '../../../util/isValidCpf';

interface StateParams {
    email?: string,
    name?: string,
    facebookId?: string,
    avatar?: string,
}

export interface RegisterUserFields extends StateParams {
    firstName: string,
    lastName: string,
    cpf: string,
    gender: "MASCULINO" | "FEMININO" | 'OUTROS' | '',
    birthday: string,
    phone: string

    email: string,
    password: string,
    repeatPassword: string,
    username: string

    zipcode: string,
    street: string,
    number: string,
    complement: string,
    neighbor: string,
    uf: string,
    city: string
}

function Register()  {
    const [loading, setLoading] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)
    
    
    const [formData, setFormData] = useState<RegisterUserFields>({
        firstName: '',
        lastName: '',
        cpf: '',
        gender: "",
        birthday: '',
        phone: '',

        email: '',
        password: '',
        repeatPassword: '',
        username: '',

        zipcode: '',
        street: '',
        number: '',
        complement: '',
        neighbor: '',
        uf: '',
        city: ''
    } as RegisterUserFields)

    const history = useHistory();
    const { state } = useLocation<StateParams & { returnUrl: string }>();

    const [citys, setCitys] = useState<string[]>([]); 
    const [ufs, setUfs] = useState<IBGEEstado[]>([]); 

    const loadUfs = initUfs;
    const loadCitys = initCitys

    useEffect(() => {
        loadUfs().then(values => {
            setUfs(values)
        })
    }, [])

    useEffect(() => {
        loadCitys(formData.uf).then(values => {
            if (values.length > 0) {
                setCitys(values);
            }
        })
    }, [formData.uf])

    useEffect(() => {
        if (state && state.name) {
            const arrayNames = state.name.split(' ');
            const firstName = arrayNames[0];
            const lastName = arrayNames[arrayNames.length - 1]
            
            setFormData(f => ({
                ...f,
                ...state,
                firstName,
                lastName
            }))
        }
    }, [state])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formData.password !== formData.repeatPassword) return;

        setLoading(true);
        try {
            await register(formData);
            if (state && state.returnUrl) {
                history.push('/login', { returnUrl: state.returnUrl });
            } else {
                history.push('/login');
            }
        } catch (error) {
            console.log('Error on register new user', error);
            alert(`Verifique seus dados.`)
        }
        setLoading(false);
    }

    function handleFormData(e : ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = e.target;
        setFormData(f => ({...f, [name]: value}));
    }
    
    const handleBlurCep = async (cep: string) => {
        setLoadingCep(true);
        try {
            const data = await getAddressInfoByCep(cep);
            setFormData(f => ({
                ...f,
                ...data
            }))
        } catch (error) {
            console.log("Error on getAddressInfoByCep", error)
        }
        setLoadingCep(false);
    }

    const handleBlurCpf = (cpf: string) => {
        if (!isValidCPF(cpf)) {
            setFormData(f => ({ ...f, cpf: '' }))
        }
    }

    return (
        <MainContainer>
            <ModalContent>
                <Form 
                    onSubmit={handleSubmit}
                >
                    <h2>Cadastre-se</h2>

                    <RegisterInput
                        name="firstName"
                        placeholder="Primeiro nome social"
                        value={formData.firstName}
                        onChange={handleFormData}
                        required
                    />

                    <RegisterInput
                        name="lastName"
                        placeholder="Sobrenome social"
                        value={formData.lastName}
                        onChange={handleFormData}
                        required
                    />

                    <InputMask 
                        mask="999.999.999-99"
                        name="cpf"
                        placeholder="CPF"
                        value={formData.cpf}
                        onChange={handleFormData}
                        onBlur={({ target }) => handleBlurCpf(target.value)}
                        required
                    />
                    
                    <RegisterSelect 
                        name="gender"
                        value={formData.gender} 
                        onChange={handleFormData} 
                        defaultValue="" 
                        width="23%"
                        required
                    >
                        <option disabled value="">Sexo</option>
                        <option value="MASCULINO">Masculino</option>
                        <option value="FEMININO">Feminino</option>
                        <option value="OUTROS">Outro</option>
                    </RegisterSelect>

                    <InputMask 
                        mask="99/99/9999"
                        width="23%"
                        name="birthday"
                        placeholder="Aniversário"
                        value={formData.birthday}
                        onChange={handleFormData}
                        required
                    />

                    <InputMask 
                        mask="(99)99999-9999"
                        name="phone"
                        type="tel"
                        placeholder="Celular"
                        value={formData.phone}
                        onChange={handleFormData}
                        required
                    />

                    <RegisterInput 
                        name="username"
                        value={formData.username}
                        onChange={handleFormData}
                        placeholder="@usuario" 
                    />
                        
                    <RegisterInput
                        width="100%"
                        name="email"
                        type="email"
                        placeholder="seu@email.com"
                        value={formData.email}
                        onChange={handleFormData}
                        required
                    />

                    
                    <RegisterInput 
                        type="password" 
                        name="password" 
                        value={formData.password}
                        onChange={handleFormData}
                        placeholder="Senha" 
                        required
                    />

                    <RegisterInput 
                        name="repeatPassword" 
                        type="password" 
                        value={formData.repeatPassword}
                        onChange={handleFormData}
                        placeholder="Repita a senha"
                        required 
                    />

                    {
                        formData.password !== formData.repeatPassword &&
                            <span>Senhas não conferem.</span>
                    }

                    <h2>Endereço</h2>

                    <InputMask 
                        mask="99999-999"
                        name="zipcode"
                        placeholder="CEP"
                        value={formData.zipcode}
                        onChange={handleFormData}
                        onBlur={({ target }) => handleBlurCep(target.value)}
                        required
                    />

                    <DivCepLoading>
                        {   
                            loadingCep ? <span style={{width: '50px !important'}}><GifLoading /></span> :
                            <DontKnowZipCode href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_blank">
                                Não sei meu CEP
                            </DontKnowZipCode>
                        }
                    </DivCepLoading>

                    <RegisterInput
                        name="street"
                        placeholder="Endereço"
                        value={formData.street}
                        onChange={handleFormData}
                        required
                    />
                    <RegisterInput
                        width="23%"
                        name="number"
                        value={formData.number}
                        onChange={handleFormData}
                        placeholder="Número"
                        required
                    />
                    <RegisterInput
                        width="23%"
                        name="complement"
                        value={formData.complement}
                        onChange={handleFormData}
                        placeholder="Complemento"
                    />
                    <RegisterInput
                        name="neighbor"
                        value={formData.neighbor}
                        onChange={handleFormData}
                        placeholder="Bairro"
                        required
                    />

                    <RegisterSelect 
                        required 
                        defaultValue="" 
                        width="23%"
                        value={formData.city}
                        name="city"
                        onChange={handleFormData}
                    >
                        <option disabled value="">Cidade</option>
                        {
                        citys && citys.map((city: any) => (
                            <option key={city} value={city}>{city}</option>
                        ))   
                        }
                    </RegisterSelect>
                    <RegisterSelect 
                        required 
                        defaultValue="" 
                        width="23%"
                        value={formData.uf}
                        name="uf"
                        onChange={handleFormData}
                    >
                        <option disabled value="">Estado</option>
                        {
                        ufs && ufs.map((uf: any) => (
                            <option key={uf.sigla} value={uf.sigla}>{uf.nome}</option>
                        ))   
                        }
                    </RegisterSelect>

                    <Row>
                        {
                        loading ? <GifLoading /> : (
                            <>
                                <VenatuButton 
                                    ml={"0px"}
                                    fs="1.5rem"
                                    width="20%"
                                    type="submit"
                                    txtColor={"var(--color-white)"}
                                >
                                    Finalizar cadastro
                                </VenatuButton>
                                <span>
                                    Ao cadastrar, declaro que sou maior de idade e aceito o <strong><Link to="/termos-e-condicoes">Contrato de Usuário</Link></strong>, as <strong><Link to="/politicas-de-privacidade">Políticas de Privacidade</Link></strong> e os <strong><Link to="/termos-e-condicoes">Termos e Condições de uso</Link> </strong>.
                                </span>
                            </>
                        )
                        }
                    </Row>
                </Form>
            </ModalContent>
        </MainContainer>
    )
}

export default Register;

