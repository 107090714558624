import Auction from '../../interfaces/Auction';
import api from '../api';

export const saveAuction = async (auction: Auction) => {
    try {
        const { data } = await api.post('/auctions', auction);
        return data;
    } catch (error) {
        console.log("===    ERROR ON saveAuction ===");
        console.log(error);
        throw error;
    }
}

export const getOne = async(id: number) => {
    try {
        const { data } = await api.get(`/auctions/${id}`)
        return data;
    } catch (error) {
        console.log("===    ERROR ON getOne auction ===");
        console.log(error);
        throw error;
    }
}


export const getAuctions = async() => {
    try {
        const { data } = await api.get(`/auctions`)
        return data;
    } catch (error) {
        console.log("===    ERROR ON getAuctions auction ===");
        console.log(error);
        throw error;
    }
}



export const getCustomerAuction = async (params = { page: 1, limit: 5 }) => {
    try {
        const { data, headers } = await api.get('/auctions-customer', { params })
        const count = Number(headers['x-total-count'])
        return {
            data,
            count
        };
    } catch (error) {
        console.log("===    ERROR ON getCustomerAuction ===");
        console.log(error);
        throw error;
    }
}

export const getAvailableBySeller = async () => {
    try {
        const { data } = await api.get('/auctions-available-seller')
        return data;
    } catch (error) {
        console.log('===    ERROR ON getAvailableBySeller   ===')
        console.log(error)
        throw error
    }
}

export const getFinishedByCustomer = async () => {
    try {
        const { data } = await api.get('/auctions-finished')
        return data;
    } catch (error) {
        console.log('===    ERROR ON getFinishedByCustomer   ===')
        console.log(error)
        throw error
    }
}

export const getPendingByCustomer = async () => {
    try {
        const { data } = await api.get('/auctions-pending')
        return data;
    } catch (error) {
        console.log('===    ERROR ON getPendingByCustomer   ===')
        console.log(error)
        throw error
    }
}

export const getAbandonedByCustomer = async () => {
    try {
        const { data } = await api.get('/auctions-abandoned')
        return data;
    } catch (error) {
        console.log('===    ERROR ON getAbandonedByCustomer   ===')
        console.log(error)
        throw error
    }
}

export const addNewParticipant = async (data: { sellerId: number, auctionId: number }) => {
    const params = data;
    try {
        const { data } = await api.post('/offers', params)
        return data;
    } catch (error) {
        console.log('===    ERROR ON getAvailableBySeller   ===')
        console.log(error)
        throw error
    }
}

export const abandon = async (id: number) => {
    try {
        await api.put(`/auctions/${id}/abandon`)
    } catch (error) {
        console.log('===    ERROR ON abandon   ===')
        console.log(error)
        throw error
    }
}