import React ,{ useState, useEffect } from 'react';
import "./style.tsx";


import { Auctions, AuctionsLayer, AuctionDiv, AuctionImage, AuctionTitle } from './style';
import { Link } from 'react-router-dom';
import { RowPanel, TitleInRowPanel } from '../../assets/GlobalStyles';
import { getAuctions } from '../../services/auction';
import GifLoading from '../GifLoading';
import Auction from '../../interfaces/Auction';
import VenatuCountDown from '../VenatuCountDown';
import getAuctionsAvailableUntil from '../../util/getAuctionAvailableUntil';
import { formatProductToRouteParams } from '../../util/formatProductToRouteParams';

const AvailableAuctions :React.FC = () => {
    const [auctions, setAuctions] = useState([] as Auction[]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initAuctions()
    }, []);

    const initAuctions = async () => {
        setLoading(true)
        try {
            const data = await getAuctions();
            console.log(data);
            setAuctions(data);
        } catch (error) {
            console.log("Error on initAuctions")
        }
        setLoading(false)
    }

  

    return (

        <Auctions>
            <RowPanel>
                <TitleInRowPanel>Leilões em andamento</TitleInRowPanel>
                <AuctionsLayer>
                {

                    loading ?
                        <GifLoading />
                    : auctions.length > 0 ? 
                        auctions.map((auction: Auction) => {
                            const availableUntil = getAuctionsAvailableUntil(auction);
                            return (
                                <AuctionDiv key={auction.id}>
                                    <Link to={`/products/${formatProductToRouteParams(auction.product, auction.state)}`} >
                                        <AuctionImage src= {auction.product?.imageUrl}></AuctionImage>
                                        <AuctionTitle >{auction.product?.title}</AuctionTitle> 
                                    </Link>
                                    
                                    <strong><VenatuCountDown date={availableUntil} /></strong>
                                </AuctionDiv>
                        )})
                    :
                        <AuctionDiv>
                            <Link to="#">
                                <AuctionTitle >Nenhuma leilão disponível</AuctionTitle> 
                            </Link>
                        </AuctionDiv>               
                }
                </AuctionsLayer>
            </RowPanel>
        </Auctions>
    )
}

export default AvailableAuctions;