import styled from 'styled-components';

export const PurchaseInfoSection = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > h3 {
      margin-bottom: 2rem;
  }

  & > div {
      margin-bottom: 2.5rem;
  }

  & > form {
      margin-bottom: 2rem;
  }

  & > form > div {
      display: flex;
      flex-direction: row;

    & > input {
        height: 2.85rem;
        padding: 1rem;
        border-radius: 5px;
        color: var(--color-primary);
        border: 1px solid var(--color-gray);
        font-size: 1.2rem;
        margin-top: 1.5rem;

        ::placeholder {
            color: var(--color-gray);
            font-size: 1.2rem;
        }
    }  
  } 
`;

export const PaymentForm = styled.form`
    display: flex;
    flex-direction: row-reverse;

    & > div {
        margin-top: 1.5rem;
    }

    & > div + div {
        display: flex;
        flex-direction: column;
        margin-top: 0;

        & > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1.5rem;
        }
    }

    & > div > input,
    & > div + div > div > input  {
        height: 2.85rem;
        padding: 1rem;
        border-radius: 5px;
        color: var(--color-primary);
        border: 1px solid var(--color-gray);
        font-size: 1.2rem;
        margin-top: 0rem;

        ::placeholder {
            color: var(--color-gray);
            font-size: 1.2rem;
        }
    }

    & > div > input + input {
        margin-top: 1.5rem;
    }

`

export const PriceInfoSection = styled.div`
  width: 25%;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 1.5rem;

  & > strong {
      color: var(--color-gray);
      font-weight: bold;
      padding-top: 1.5rem;
      width: 90%;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var(--color-gray);
  }

  & > div {
    width: 90%;
      & > * {
          margin-top: 1.5rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
      }

      & > p {
          font-size: 1rem;

          & > span {
              font-weight: bold;
          }
      }
  }



`

export const PurchaseIdText = styled.h3`
    font-size: 1rem;
    font-weight: normal;
`

export const PurchaseEconomyText = styled.span`
    font-size: 1rem;
    font-weight: normal;
    color: var(--color-primary);
    & > span {
        font-weight: bold;
    }
`
export const PurchaseTitleText = styled.h1`
    width: 100%;
    display: flex;
    align-items: center;
    & > a {
        font-size: 1rem;
        color: var(--color-secondary);
        margin-left: 1rem;
    }
`

export const PurchaseInfoTitle = styled.strong`
    font-size: 1.52rem;
    color: var(--color-strong-gray);
`

export const PurchaseMainAddressText = styled.p`
    font-size: 1rem;
    line-height: 21px;
    font-weight: bold;
`
export const PurchaseSecondaryAddressText = styled.span`
    font-size: 1rem;
    line-height: 21px;
`

export const PurchaseInternLink = styled.button`
    display: block;
    background-color: transparent;
    border: none;
    color: var(--color-secondary);
    font-weight: bold;
    cursor: pointer;
`

export const HistoryTransaction = styled.div`
    display: flex;
    flex-direction: column;
`
