import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: calc(100vh - 12rem);
    height: 100vh;
    width: 100vw;

    @media(min-width: 1100px) {
        max-height: calc(100vh - 8rem);
        align-items: center;
        display: flex;
        justify-content: center;
    }
`

export const Content = styled.div`
    height: calc(100vh - 40%);
    width: calc(100vw - 30%);
    background-color: var(--color-primary);
    align-items: center;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    border-radius: 10px;

    @media(min-width: 1100px) {
        height: calc(100vh - 27%);
        align-self: center;
    }
`

export const Logo = styled.div`
    align-self: center;
    align-items: center;
    max-width: 60%;
    width: 50%;
    height: 30%;
    max-height: calc(100% - 65%);
    margin-top: 5%;
    
    @media(max-width: 1100px) {
        align-self: center;
        align-items: center;
        width: 60%;
        height: calc(100% - 65%);
        margin-top: 5%;
    }
`

export const LogoImage = styled.img`
    max-height: 100%;
    max-width: 100%;
    
    @media(max-width: 1100px) {
        max-height: 8rem;
        max-width: 100%;
    }
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0.5rem;

    div {
        margin-top: 1rem;
    }

    label {
        font-size: 1.4rem;
    }
    input {
        width: calc(100% - 2rem);
        height: 2.6rem;
        font-size: 1.4rem;
        padding-left: 0.5rem;
    }
    button {
        font-size: 1.4rem;
    }

    @media(min-width: 1100px) {
        label {
            font-size: 1.4rem;
        }
        input {
            width: 24rem;
            height: 3.6rem;
            font-size: 1.4rem;
        }
        button {
            font-size: 1.4rem;
        }
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem;
    width: 100%;
`


export const Div49 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49%;
`

export const Button = styled.button<({ bgColor?: string, color?: string})>`
    width: 100%;
    text-align: center;
    background-color: ${(props : any) => props.bgColor || 'var(--color-secondary)'};
    height: 2.6rem;
    border: 0;
    color: ${(props : any) => props.color || 'var(--color-primary)'};
    font-family: 'moskextra-bold_800';
    border-radius: 0.7rem;
    cursor: pointer;
`
