import React from 'react';
import Product from '../../interfaces/Product';
import CurrencyText from '../CurrencyText';
import ProductState from '../ProductState';

import { ProductSection, ProductImage, ProductDetail, Title } from './styles';

interface SimpleProductViewProps {
    product: Product,
    price: number
}

const SimpleProductView: React.FC<SimpleProductViewProps> = ({
    product,
    price
}) => {

  return(
    <ProductSection>
        <ProductImage src={product.imageUrl} />
        <ProductDetail>
            <Title>{product.title}</Title>
            <ProductState state={product.state} />
            <p>Lance inicial: <CurrencyText value={price} /></p>
        </ProductDetail>
    </ProductSection>
)
}

export default SimpleProductView;