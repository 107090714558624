import React from 'react';
import { Link } from 'react-router-dom';
import { LeftSidePanel } from '../../assets/GlobalStyles';
import { Section, Title, LinkTitle } from './style';
import { useRouteMatch } from 'react-router-dom';
import Modal from 'react-modal'


const SettingsSideBar: React.FC = () => {
  const { path } = useRouteMatch()
  const menu = [
    {
      title: "Compras",
      links: [
        { link: "/perfil/comprar/leiloes", title: "Leilões", active: path.endsWith('/comprar/leiloes')},
        { link: "/perfil/comprar/pendentes", title: "Pendentes", active: path.endsWith('/comprar/pendentes')},
        { link: "/perfil/comprar/finalizados", title: "Finalizados", active: path.endsWith('/comprar/finalizados')},
        { link: "/perfil/comprar/abandonados", title: "Abandonados", active: path.endsWith('/comprar/abandonados')}
      ]
    },
    {
      title: "Vendas",
      links: [
        { link: "/perfil/vender/lances", title: "Lances", active: path.endsWith('/vender/lances')},
        { link: "/perfil/vender/arremates", title: "Arremates", active: path.endsWith('/vender/arremates')},
        { link: "/perfil/vender/finalizados", title: "Finalizados", active: path.endsWith('/vender/finalizados')},
        { link: "/perfil/vender/produtos", title: "Produtos", active: path.endsWith('/vender/produtos')},
      ]
    }
  ]

  return (
    <LeftSidePanel>
      {menu.map(item => (
        <Section key={item.title}>
          <Title>{item.title}</Title>
          {item.links.map(link => (
            <LinkTitle key={link.link} active={link.active}>
              <Link to={link.link}>{link.title}</Link>
              
            </LinkTitle>
          ))}
        </Section>
      ))}
    </LeftSidePanel>
  );
}

export default SettingsSideBar;