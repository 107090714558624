import Bid from '../../interfaces/Bid';
import api from '../api';

export const findAllBidsByAuctionId = async (id: number) : Promise<Bid[]>  => {
    try {
        const { data } = await api.get(`/bids/auction/${id}`);
        return data;
    } catch (error) {
        console.log('===    ERROR ON FIND ALL BIDS BY AUCTION ID    ===')
        console.log(error);
        throw error;
    }
}

export const addNewBid = async (data: { offerId: number, bid: number }) => {
    const params = data;
    try {
        const { data } = await api.post('/bids', params)
        return data;
    } catch (error) {
        console.log("===    ERROR ON addNewBid  ===")
        console.log(error);
        throw error;
    }
}