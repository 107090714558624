import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Auction from '../../../interfaces/Auction';
import Bid from '../../../interfaces/Bid';
import { Row } from '../../../pages/Auth/Login/style';
import api from '../../../services/api';
import { abandon } from '../../../services/auction';
import { findAllBidsByAuctionId } from '../../../services/bids';
import { initPurchase } from '../../../services/purchase';
import getAuctionsAvailableUntil from '../../../util/getAuctionAvailableUntil';
import BidsTable from '../../BidsTable';
import CurrencyText from '../../CurrencyText';
import GifLoading from '../../GifLoading';
import VenatuButton from '../../VenatuButton';
import VenatuCountDown from '../../VenatuCountDown';
import { TableContainer } from '../style';

interface MyAuctionBoxProps {
  auction: Auction
}

const MyAuctionBox: React.FC<MyAuctionBoxProps> = ({
  auction
}) => {
  const [bids, setBids] = useState<Bid[]>([]);
  const [currentPrice, setCurrentPrice] = useState(auction.initialBid);
  const availableUntil = getAuctionsAvailableUntil(auction);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        initBids()

        if (auction.hasPurchased === true) {
          history.push(`/perfil/leilao/${auction.id}/finalizar`)
        }
    }, [auction.id])
    
    useEffect(() => {
      let less: number = auction.initialBid;
      if (bids) {
        bids.forEach(bid => {
          if (bid.currentPrice < less) less = bid.currentPrice
        })
        setCurrentPrice(less);
      }
    }, [bids])

    const initBids = async () => {
        try {
            const data = await findAllBidsByAuctionId(auction.id)
            setBids(data);
        } catch (error) {
        }
    }

    const handleAbandonAuction = async () => {
      try {
        await abandon(auction.id)
        history.goBack();
      } catch (error) {
        alert("Erro inesperado")
      }
    }

    const handleInitPurchase = async () => {
      setLoading(true)
      try {
        await initPurchase(auction.id)
        history.push(`/perfil/leilao/${auction.id}/finalizar`)
      } catch (error) {
        console.log(error);
        alert("Erro inesperado");
      }
      setLoading(false);
    }

  return (
    loading ? 
      <TableContainer>
        <GifLoading />
      </TableContainer>
    :
    auction.abandoned ? 
      <TableContainer>
        <header><strong>Leilão abandonado!</strong></header>
      </TableContainer>
    :
    <TableContainer>
       {
          auction.available ?
            <header>
              <p>Leilão em andamento</p>
              <strong>Resta <VenatuCountDown date={availableUntil}>finish</VenatuCountDown></strong>  
            </header>
          :
            <header>
              <p>Leilão encerrado</p>
              <p>Melhor oferta: <strong><CurrencyText value={currentPrice} /></strong></p>
            </header>

      }

      <main>
        <BidsTable bids={bids} auction={auction} />
      </main>

      {!auction.abandoned &&
          <footer>
              <Row>
              {
                !auction.available  &&
                  <VenatuButton onClick={handleInitPurchase} txtColor={"var(--color-white)"}>Arrematar</VenatuButton>
              }
                <VenatuButton onClick={handleAbandonAuction} bgColor={"var(--color-white)"} txtColor={"var(--color-secondary)"}>Cancelar leilão</VenatuButton>
              </Row>
          </footer>
       }
    </TableContainer>
  )
}

export default MyAuctionBox;