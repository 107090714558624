import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { getProduct } from '../../services/product';
import { MainContainer } from '../../assets/GlobalStyles';
import ProductMainView from '../../components/ProductMainView';
import ProductDetailView from '../../components/ProductDetailView';
import IProduct from '../../interfaces/Product';
import queryString from 'query-string';

interface ProductParams {
    id: string,
    slug: string
}

const Product = () => {
    const { id } = useParams<ProductParams>();
    const { state } = queryString.parse(useLocation().search);
    const [product, setProduct] = useState({} as IProduct);

    const history = useHistory();

    useEffect(() => {
        initProduct();
    }, [id])

    const initProduct = async () => {
        try {
            const queryState = state === "USED" ? "USED" : "NEW";
            const p =  await getProduct(Number(id), queryState);
            p.state = queryState;
            if (!p.showcasePrice) {
                history.push("/404-conteudo-nao-encontrado")
            }
            setProduct(p)
        } catch (error) {
            history.push("/404-conteudo-nao-encontrado")
        }
    }

    return (
        <MainContainer>
            <ProductMainView product={product} />
            {/* <SimilarProducts /> */}
            <ProductDetailView product={product} />
            {/* <AlsoBoughtByView /> */}
        </MainContainer>
    )
}

export default Product;