import styled from 'styled-components'

export {
    Categories,
    CategoryDiv,
    CategoriesLayer,
    CategoryImage,
    CategoryTitle
}

const Categories = styled.div`
    
    max-width: 100vw;
    background-color: #F2F2F2;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 1.2rem;
    overflow: hidden;   
    padding-bottom: 2rem;
`


const CategoriesLayer = styled.div`
    max-height:60%;
    display:flex;
    flex-direction: row;
    width:100%;
    justify-content: space-between;
`

const CategoryDiv = styled.div`
    max-height: 10.6rem;
    max-width: 12rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 1.2rem 3rem;
    box-shadow: var(--color-gray) 0px 1px 1px;
`

const CategoryTitle = styled.p`
    color: var(--color-secondary);
    text-align: center;
`

const CategoryImage = styled.img`
    max-height: 5rem;
    width: 100%;
    @media(max-width: 1100px) {
        max-height: 4rem;
    }

`