import styled from 'styled-components';

export {
    ProductInsertDiv,
    ProductInsertContainer,
    Title,
    ButtonsDiv
}

const ProductInsertContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap:  wrap;
    margin: 1rem;
    justify-content: center;
    align-items:center;
    
`
const ProductInsertDiv = styled.div`
        background-color: white;
        display: flex;
        width: 28.1rem;
        height:13.5rem;   
        justify-content: center;
        align-items:center;   
        flex-direction: column;
        box-shadow: var(--color-gray) 0px 1px 1px;
`
const Title = styled.p`
    max-width: 100vw;
    display:flex;
    font-family: var(--font-secondary-medium);
    font-size:1.62rem;
    color: var(--color-strong-gray);
    
    padding-bottom: 1rem;
    padding-top: 3rem;
    align-self: center;
`
const ButtonsDiv = styled.div`
    display: flex;
    justify-content:center;
    height:50%;
    flex-direction: row;
    width:70%;
    align-items: center;
`