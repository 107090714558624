import React, { useState } from 'react';
import { useAuth } from '../../contexts/auth';
import Auction from '../../interfaces/Auction';
import { addNewParticipant } from '../../services/auction';
import MyAuctionBox from './MyAuctionBox';
import NotParticipateBox from './NotParticipateBox';
import ParticipateBox from './ParticipateBox';

// import { Container } from './styles';
interface OfferBoxProps {
    auction: Auction,
}

const OfferBox: React.FC<OfferBoxProps> = ({
    auction,
}) => {
    const { sellerProfile, buyerProfile } = useAuth();
    const [offerUser, setOffersUser] = useState(
        auction.offers?.find(offer => 
            offer.sellerId === sellerProfile?.id
        )?.seller
    )    
    const offer = auction.offers?.find(offer => offer.sellerId === sellerProfile?.id)
    const auctionUser = auction.buyer;

    const handleOnParticipate = async () => {
        try {
            const params = {
                sellerId: sellerProfile?.id || -1,
                auctionId: auction.id
            }

            await addNewParticipant(params);
            setOffersUser(sellerProfile)
        } catch (error) {
            console.log("===    ERROR ON handleOnParticipate - OfferBox ===")
            console.log(error)
            alert('Ops, o servidor retornou um erro inesperado.')
        }
    }
    
    return (
        auctionUser.id === buyerProfile?.id ?
            <MyAuctionBox auction={auction} />
        : offerUser ?
            <ParticipateBox auction={auction} offer={offer} />
        :
            <NotParticipateBox auction={auction} onParticipate={handleOnParticipate} />
    )
}

export default OfferBox;