import styled from 'styled-components'

export {
    Footer,
    FooterTop,
    FooterBot
}

const Footer = styled.footer`
    max-width: 100vw;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1.2rem;
    overflow: hidden;   
    padding-top: 3rem;
    padding-bottom: 2rem;
    height: 9rem;
`
const FooterTop = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 1rem;
    font-family: var(--font-secondary-bold);
`

const FooterBot = styled.div`
    align-items: center;
    color: #A7A9AB;
    font-family: var(--font-secondary);
`