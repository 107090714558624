import React from 'react';
import AvailableAuctions from '../../components/AvailableAuctions';
import AvailableCategories from '../../components/AvailableCategories'
import Banner from './Banner'

function Home() {
    return (
        <div>
            <Banner></Banner>
            <AvailableAuctions />
            <AvailableCategories></AvailableCategories>
        </div>
    )
}

export default Home;