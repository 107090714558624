import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropzoneContainer, DropzoneInput, DropzoneLabel, DropzoneImage } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import IFileUploaded from '../../interfaces/IFileUploaded';

interface Props {
    onFileUploaded: (file: IFileUploaded, index: number) => void,
    main?:  boolean,
    index: number              
}
const CustomDropzone:React.FC<Props> = (
    {   onFileUploaded, 
        main = false,
        index = 0
    }) => {
    
        const [selected, setSelected] = useState("");
        const MAX_SIZE = 5242880;
        const ACCEPTED_FILES = "image/*"

    const onDrop = useCallback((accepted:File[]) => {
        const my_files: any[] = accepted.map((each:File) => {
            const fileUrl = URL.createObjectURL(each);
            const my_file = {
                file: each, url: fileUrl
            };
            return my_file;
        })

        if (my_files.length > 0)
            setSelected(my_files[0].url)

        onFileUploaded(my_files[0], index); 
    }, [onFileUploaded]);

    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        accept: ACCEPTED_FILES,
        onDrop,
        minSize: 0,
        maxSize: MAX_SIZE,
        multiple: false,
    })

    const isFileTooLarge = fileRejections.length > 0 && fileRejections.filter(f=>f.file.size > MAX_SIZE).length > 0;

    return (
        <DropzoneContainer { ...getRootProps()} className="dropzone">
            <DropzoneInput { ...getInputProps()} accept={ACCEPTED_FILES} />
            {
            <DropzoneLabel> 
                { 
                selected ?
                    <DropzoneImage src={selected} alt="Imagem escolhida" />
                :
                    <>
                        {main && <span>Foto principal</span>}
                        <FontAwesomeIcon icon={faUpload} size="2x" color={'var(--color-secondary)'} />
                        {isDragActive && !isDragReject && "Solte a imagem."}
                        {isDragReject && "Tipo de arquivo não permitido!"}
                        {isFileTooLarge && "Arquivo é muito pesado, tamanho máximo permitido: 500kb"}
                    </>
                }
            </DropzoneLabel>
            }
        </DropzoneContainer>
    )
}

export default CustomDropzone;