import styled from 'styled-components'

export {
    Title,
    Text,
    DivContainer
}

const DivContainer = styled.div`
    padding: 2rem;
    width: 95%;
    text-align: justify;
    
    & > strong {
        margin-top: 2.5rem;
    }

    & > p {
        margin-top: 1rem;
    }
`

const Title = styled.strong`
    max-width: 100vw;
    display:flex;
    font-family: var(--font-secondary-bold);
`

const Text = styled.p`
    max-width: 100vw;
    display:flex;
    font-family: var(--font-secondary);
`