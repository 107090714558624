import React from 'react';
import "./style.tsx";

import { Text , Title, DivContainer } from './style';
import { MainContainer } from '../../assets/GlobalStyles';

const TermosECondicoes = () => {
    return (
        
        <MainContainer>
            <DivContainer>
                <Title>POLÍTICA DE COMPRA E VENDA</Title>
                <Text>Termos e Condições de Compra e Venda de VENATU.COM.BR LTDA,   com sede na Rua Afonso Pena, 384, sala 01, bairro Canto, Florianópolis/SC, CEP 88070-, doravante denominada simplesmente VENATU, e, de outro lado, o cliente, qualificado no momento da compra dos produtos na plataforma VENATU, doravante denominado simplesmente Cliente.</Text>
                <Text>Considerando que a VENATU  realiza venda de produtos e serviços pela internet; Considerando o interesse do Cliente na compra dos produtos oferecidos pela VENATU ("Produtos") em seus canais de venda; </Text>
                <Text>O presente contrato tem por finalidade estabelecer as condições gerais de uso e compra de produtos e serviços do cliente do site VENATU.</Text>
        
                <Title>I. Confidencialidade:</Title> 
                <Text>É de responsabilidade da VENATU  a preservação da confidencialidade de todos os dados e informações fornecidos pelo Cliente no processo de compra. A segurança do site é auditada diariamente e garantida contra a ação de hackers, através do selo especializado.</Text>
                
                <Title>II. Serviço de Atendimento ao Cliente (SAC):</Title> 
                <Text>O cliente dispõe desse serviço para sanar suas dúvidas, solucionar eventuais solicitações ou reclamações a respeito do seu pedido ou de qualquer conteúdo disponibilizado no site. O SAC poderá ser acionado por meio de telefone ou de formulário do site.</Text>
                
                <Title>III. Política de entrega:</Title>
                <Text>O prazo para entrega dos Produtos é informado durante o procedimento de compra, contabilizado em dias úteis. As entregas dos Produtos são realizadas de segunda a sexta-feira, das 8h às 22h. Excepcionalmente, algumas entregas de Produtos podem ocorrer aos sábados, domingos e feriados.</Text>

                <Title>III.I</Title>
                <Text>A conferência da adequação das dimensões do produto é de responsabilidade do Cliente, que deverá se assegurar de que estas estão de acordo com os limites espaciais dos elevadores, portas e corredores do local da entrega. Não será realizada a montagem ou desmontagem do produto, transporte pela escada e/ou portas e janelas, ou içamento das entregas.</Text>

                <Title>III.II</Title>
                <Text>O prazo de entrega informado durante o procedimento de compra do Produto leva em consideração o estoque, a região, o processo de emissão da nota fiscal e o tempo de preparo do produto. A cada atualização no status de entrega do pedido, o sistema da VENATU envia, automaticamente, e-mails de alerta para o Cliente.</Text>
                
                <Title>III.II</Title>
                <Text>O valor do frete da entrega é calculado com base no local de entrega, peso e dimensões do Produto.</Text>
                
                <Title>III.III</Title>
                <Text>A VENATU  não autoriza a transportadora a: entrar no domicílio; entregar por meios alternativos (exemplo: içar produto por janela); realizar instalação ou manutenção de produtos; abrir a embalagem do produto; realizar entrega em endereço diferente do que consta no DANFE; realizar entrega a menor de idade ou sem documento de identificação.</Text>
                
                <Title>III.IV</Title>
                <Text>A VENATU não se responsabiliza pela retenção de mercadorias na SEFAZ quando esta se dever exclusivamente a pendências do cliente, sendo, portanto, necessário seu comparecimento no posto fiscal para que a mercadoria seja liberada, tendo em vista que nestes casos as informações referentes a liberações e pagamentos só são passadas aos interessados.</Text>
                
                <Title>IV.</Title>
                <Text>Direito de arrependimento: ao Cliente será facultado o exercício do direito de arrependimento da compra, com a finalidade de devolução do Produto, hipótese na qual deverão ser observadas as seguintes condições: o prazo de desistência da compra do produto é de até 7 (sete) dias corridos, a contar da data do recebimento; em caso de devolução, o produto deverá ser devolvido à ies na embalagem original, acompanhado do DANFE (Documento Auxiliar da Nota Fiscal Eletrônica), do manual e de todos os seus acessórios.</Text>
                
                <Title>IV.I</Title>
                <Text>O Cliente deverá solicitar a devolução através do Serviço de Atendimento ao Cliente (SAC) ou diretamente no Painel de Controle, no tópico "cancelar pedido". As despesas decorrentes de coleta ou postagem do Produto serão custeadas pela VENATU.</Text>

                <Title>IV.II</Title>
                <Text>Após a chegada do produto ao Centro de Distribuição, a VENATU verificará se as condições supra citadas foram atendidas. Em caso afirmativo, providenciará a restituição no valor total da compra.</Text>

                <Title>IV.III</Title>
                <Text>Em compras com cartão de crédito a administradora do cartão será notificada e o estorno ocorrerá na fatura seguinte ou na posterior, de uma só vez, seja qual for o número de parcelas utilizado na compra. O prazo de ressarcimento e, ainda, a cobrança das parcelas remanescentes após o estorno integral do valor do Produto no cartão de crédito do Cliente realizado pela VENATU, é de responsabilidade da administradora do cartão. Na hipótese de cobrança de parcelas futuras pela administradora do cartão, o Cliente não será onerado, vez que a VENATU, conforme mencionado acima, realiza o estorno do valor integral do Produto em uma única vez, sendo o crédito referente ao estorno concedido integralmente pela administradora do cartão na fatura de cobrança subsequente ao mês do cancelamento.</Text>

                <Title>IV.IV</Title>
                <Text>Em compras pagas com boleto bancário ou débito em conta, a restituição será efetuada por meio de depósito bancário, em até 10 (dez) dias úteis, somente na conta corrente do(a) comprador(a), que deve ser individual. É necessário que o CPF do titular da conta corrente.</Text>
            </DivContainer>
        </MainContainer>
    );
}

export default TermosECondicoes;