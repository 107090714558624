import styled, { createGlobalStyle } from "styled-components";

import JelleeWoff from './fonts/jellee-roman-webfont.woff';
import JelleeWoff2 from './fonts/jellee-roman-webfont.woff2';

import MoskLight300Woff from './fonts/mosk-light-300-webfont.woff'
import MoskLight300Woff2 from './fonts/mosk-light-300-webfont.woff2'

import MoskMedium500Woff from './fonts/mosk_medium_500-webfont.woff'
import MoskMedium500Woff2 from './fonts/mosk_medium_500-webfont.woff2'

import MoskExtraBold500Woff from './fonts/mosk_extra-bold_800-webfont.woff'
import MoskExtraBold500Woff2 from './fonts/mosk_extra-bold_800-webfont.woff2'

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'jelleebold';
        src: url(${JelleeWoff2}) format('woff2'),
            url(${JelleeWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'mosklight_300';
        src: url(${MoskLight300Woff2}) format('woff2'),
            url(${MoskLight300Woff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'moskmedium_500';
        src: url(${MoskMedium500Woff2}) format('woff2'),
            url(${MoskMedium500Woff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'moskextra-bold_800';
        src: url(${MoskExtraBold500Woff2}) format('woff2'),
            url(${MoskExtraBold500Woff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    *:focus {
        outline-color: var(--color-gray);
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'moskmedium_500';
    }

    *::after, *::before {
        box-sizing: border-box;
    }

    a, a:link {
        text-decoration: none !important;
    }
    
    :root {
        --color-primary: #712783;
        --color-secondary: #FF9000;
        --color-gray: #A7A9AB;
        --color-strong-gray: #616162;
        --color-white: #fff;
        --font-primary: jelleebold;
        --font-secondary: mosklight_300;
        --font-secondary-medium: moskmedium_500;
        --font-secondary-bold: moskextra-bold_800;
    }
    
`

export const MainContainer = styled.div`
    height: 100%;
    background: #F2F2F2;
    opacity: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: max(calc(100vh - 15rem), 37.5rem);
`

export const MainContent = styled.div`
    padding: 2rem;
    width: 95%;
    display: flex;
`

export const MainForm = styled.form`
    margin: 2.8rem;
    padding: 4rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    background-color: white;
`

export const Row = styled.div<({ patternWidth? : string })>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: ${(props: any) => props.patternWidth || '100%'};
`

export const RowPanel = styled.section`
    margin: 0 2.2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const TitleInRowPanel = styled.p`
    margin: 4.36rem 0;
    font-size: 1.62rem;
    color: var(--color-strong-gray);
`

export const LeftSidePanel = styled.section`
    width: 35%;
    display: flex;
    flex-direction: column;
    p {
        margin: 0rem 0;
    }
`

export const InfoPanel = styled.section`
    width: 65%;
    display: flex;
    flex-direction: column;

    & > h1 {
        color: var(--color-strong-gray);
        margin-bottom: 2rem;
        font-size: 1.62rem;
    }


    & > h2 {
        color: var(--color-black);
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }

    & > h3 {
        font-size: 1rem;
        margin-top: 1rem;
    }

    & > h3 + p {
        text-align: center;
    }

    & > h3 + h3 {
        margin-top: 2rem;
    }

    & > div > p {
        text-align: center;
    }
`