import styled from "styled-components";

export {
    FilterPanel,
    DataPanel,
    FilterBreadcrumb
}

const FilterPanel = styled.div`
    width: 35%;

    p {
        margin: 0rem 0;
    }
`

const FilterBreadcrumb = styled.div`
    font-family: moskmedium_500;
    color: var(--color-gray);
    margin: 0 0 2rem 0;

`

const DataPanel = styled.div`
    width: 65%;

    & > p {
        text-align: center;
    }
`