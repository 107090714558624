import api from '../api';

export const getProducts = async () => {
    try {
        const { data } = await api.get(`products`);
        return data; 
    } catch (err) {
        console.log('===    ERROR ON getProducts     ===')
        console.log(err);
    }
}

export const getProduct = async (id: number, state: "USED" | "NEW") => {
    try {
        const { data } = await api.get(`products/${id}?state=${state}`);
        return data;
    } catch (err) {
        console.log('===    ERROR ON getProducts     ===')
        console.log(err);
        throw err;
    }
}

export const getProductsBySeller = async (params = { page: 1, limit: 5 }) => {
    try {
        const { data, headers } = await api.get('products-seller', { params })
        const count = Number(headers['x-total-count'])
        return { data, count };
    } catch (error) {
        console.log("===    ERROR ON getProductsBySeller    ===")
        console.log(error);
        throw error;
    }
} 

export const saveProduct = async (data: any) => {
    try {
        return await api.post(`products`, data); 
    } catch (err) {
        console.log('===    ERROR ON saveProduct     ===')
        throw err;
    }
}