import styled from "styled-components";
import { SelectHTMLAttributes } from "react";

export {
    Dropdown
}

interface PropsVenatuDropdown extends SelectHTMLAttributes<HTMLSelectElement> {

}

const Dropdown = styled.select<PropsVenatuDropdown>`
    width: 100%;
    height: 2.8rem;
    text-align: center;
    padding: 0 1rem;
    border-radius: 5px;
    box-shadow: var(--color-gray) 0px 0px 0px;
    background-color: #fff;
    color: var(--color-gray);
    font-size: 1rem;
    border: 0.5px solid var(--color-gray);
`