import React from 'react';
import styled from 'styled-components';

interface BreadcrumbProps  {
    object: any,
    type?: string
}

const Breadcrumb = styled.p`
    font-family: var(--font-secondary-bold);
    color: var(--color-secondary);
    font-size: 1rem;
    text-align: left;
    height: 1.2rem;
    margin: 1rem 4.4rem;
`

const Breadcrumbs : React.FC<BreadcrumbProps> = ({
    object,
    type
}) => {
    return (
        <Breadcrumb>
            {object.category && `${object.category.title} > ${object.title}`}
        </Breadcrumb>
    )
}

export default Breadcrumbs;