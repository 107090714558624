import React, { useState, useEffect } from 'react';

import { Pagination, ItemPagination } from './style';

interface PaginationProps {
    pageClick : (page: number) => {},
    totalRecords: number, 
    initialPage?: number,
    perPage?: number, 
    maxPageShow?: number
}
const ListPagination : React.FC<PaginationProps> = ({ 
    pageClick, 
    initialPage = 1, 
    totalRecords = 0, 
    perPage = 5, 
    maxPageShow = 7
}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [countPages, setCountPages] = useState(1);
    const [listPages, setListPages] = useState<number[]>([]);

    const [firstPageShow, setFirstPageShow] = useState(1);
    const [lastPageShow, setLastPageShow] = useState(countPages);

    useEffect(() => {
        setCurrentPage(initialPage)
    }, [initialPage])

    useEffect(() => {
        setCountPages(Math.ceil(totalRecords / perPage));
        setCurrentPage(initialPage);
    }, [totalRecords, perPage]);

    useEffect(() => {
        const half = Math.floor(maxPageShow / 2);
        let firstPage = currentPage - half;
        let lastPage = currentPage + half;

        if (lastPage < maxPageShow)
            lastPage = maxPageShow;

        if (currentPage === countPages)
            firstPage = countPages - half * 2;

        if (firstPage + half * 2 <= countPages)
            setFirstPageShow(firstPage > 0 ? firstPage : 1);
        
        setLastPageShow(lastPage > countPages ? countPages : lastPage);
    }, [currentPage, countPages, maxPageShow])

    useEffect(() => {
        let count_pages: number[] = [];
        for (let i: number = 0; i < countPages; i++)
            count_pages.push(i + 1);
        
        setListPages(count_pages);
    }, [countPages])

    function handleClick(page: number) {
        if (page < 1 || page > countPages) return;
        setCurrentPage(page);
        
        if(pageClick)
            pageClick(page);
    }

    return (
        <Pagination>
            <ItemPagination className="active" onClick={() => handleClick(currentPage - 1)}> {`${'<'}`} </ItemPagination>
            {
                firstPageShow !== 1 &&
                <ItemPagination onClick={() => handleClick(1)}>...</ItemPagination>
            }
            {
                listPages
                .filter(n => n >= firstPageShow && n <= lastPageShow)
                .map(numberPage => {
                    let className = '';
                    if (numberPage === currentPage) className = "active"
                    console.log("np cp ", numberPage, currentPage)
                    return (
                        <ItemPagination 
                            key={numberPage} 
                            className={className} 
                            onClick={() => handleClick(numberPage)}
                        > 
                            {numberPage} 
                        </ItemPagination>
                    )
                })
            }
             {
                lastPageShow !== countPages &&
                <ItemPagination onClick={() => handleClick(countPages)}>...</ItemPagination>
            }
            <ItemPagination className="active" onClick={() => handleClick(currentPage + 1)}>{`${'>'}`}</ItemPagination>
        </Pagination>
    )
}

export default ListPagination;
