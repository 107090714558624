import styled from "styled-components";

export const TableContainer = styled.div`
    height: 25rem;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > header {
        padding: 1.1rem 0;
        height: 7.2rem;
        background-color: var(--color-primary);
        color: var(--color-white);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        
        & > p {
            font-size: 1rem;
        }

        & > strong {
            font-size: 1.5rem;
        }
    }

    & > main {
        width: 95%;
        height: 100%;
        max-height: 20rem;
        overflow-y: scroll;
        padding: 0.5rem 0;
        align-self: center;

        display: flex;
        flex-direction: column;

        & > :first-child {
            margin-bottom: 1rem;
        }

    }

    & > footer {
        width: 80%;
        margin: 0 auto;
    }
`;
