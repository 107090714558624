import styled from 'styled-components'

export {
    Header,
    Logo,
    LogoImage, 
    SearchForm,
    SearchInput,
    Links,
    UlLinks,
    HeaderLink,
}

const Header = styled.header`
    height: 4.68rem;
    max-width: 100vw;
    background-color: var(--color-primary);
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.2rem 2.2rem;
    overflow: hidden;

    @media(max-width: 1100px) {
        max-height: 100vh;
        height: 100%;
        z-index: 1;
    }
`

const Logo = styled.div`
    align-self: center;
    align-items: center;
    width: 3.2rem;
    height: 3.2rem;

    @media(max-width: 1100px) {
        align-self: flex-start;
    }
`

const LogoImage = styled.img`
    max-height: 5rem;
    width: 100%;
    @media(max-width: 1100px) {
        max-height: 4rem;
    }
`

const SearchForm = styled.form`
    margin: 0 1.6rem;
    max-width: 60%;
    width: calc(60%);
    max-height: 2.5rem;
    flex-direction: row;
    display: flex;

    @media(max-width: 1100px) {
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 0;
        max-width: 100%;
        width: 100vw;
    }
`

const SearchInput = styled.input`
    max-height: 2.5rem;
    border-radius: 1px;
    border: 0;
    width: 37.5rem;
    font-family: mosklight_300;
    font-size: 1rem;
    padding: 1.2rem;
    color: var(--color-primary);

    ::placeholder {
        color: var(--color-primary);
    }

    @media(max-width: 1100px) {
        width: 100vw;
        background-color: var(--color-secondary);
    }
`


const Links = styled.nav`
    width: calc(100vw - 70%);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: center;
    color: var(--color-secondary);
    transition: all 1s ease;

    @media(max-width: 1100px) {
        margin-right: 1rem;
    }
`

const UlLinks = styled.ul<{open : boolean}>`
    display: flex;
    transition: all 1s ease;
    display: flex;
    list-style: none;
    @media(max-width: 1100px) {
        flex-direction: column !important;
        display: ${({ open }) => open ? 'flex' : 'none'};
        align-items: flex-end;

        li:first-child {
            margin-top: 5.4rem;
        }

        li {
            line-height: 2rem;
            margin-right: 0;
        }
    }
`

const HeaderLink = styled.li<{current?: boolean}>`
    margin-right: 1.6rem;
    font-size: 1rem;
    border-bottom: ${({current}) => current ? '1px solid var(--color-secondary)' : '0'};
    max-width: 100%;
    height: 1.5rem;
    flex-direction: column;
    align-items: center;
    a {
        text-decoration: none;
        color: var(--color-secondary);
    }

    small {
        font-size: 1.1rem;
    }

    @media(max-width: 1100px) {
        flex-direction: row;
    }

`


