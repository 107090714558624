import React, { useEffect, useState } from 'react';
import Product from '../../interfaces/Product';
import { MainContent, RowPanel, TitleInRowPanel } from '../../assets/GlobalStyles';

import { DivProductDetail } from './style';
import InfoDetailProduct from '../InfoDetailProduct';
import GifLoading from '../GifLoading';
import IProduct from '../../interfaces/Product';

interface DetailProductProps {
    product: Product
}
const ProductDetailView : React.FC<DetailProductProps> = ({ 
    product 
}) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(product === {} as IProduct);
    }, [product])

    return (
        loading ? <MainContent><GifLoading /></MainContent> :
        <MainContent>
            <RowPanel>
                <TitleInRowPanel>Detalhes do produto</TitleInRowPanel>

                <DivProductDetail>
                    <InfoDetailProduct label="Marca" value={product.brand}   />
                    <InfoDetailProduct label="Modelo" value={product.model}   />
                    <InfoDetailProduct label="Fabricante" value={product.manufacturer}   />
                    <InfoDetailProduct label="EAN" value={product.universalCode}   />
                    <InfoDetailProduct label="Voltagem" value={product.voltage}   />
                    <InfoDetailProduct label="Cor" value={product.color}   />
                </DivProductDetail>

                <InfoDetailProduct label="Descrição" value={product.description}   />
            </RowPanel>
        </MainContent>
    )
}

export default ProductDetailView;