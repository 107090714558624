import styled from 'styled-components';

export const DropzoneContainer = styled.div.attrs({
    className: "dropzone"
})`
    height: 7rem;
    width: 7rem;
    background: transparent;
    border-radius: 10px;
    border: 1px solid var(--color-gray);
  
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0;
`;

export const DropzoneInput = styled.input`

`;

export const DropzoneLabel = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-secondary) !important;
    position: relative;
    & > span {
        position: absolute;
        top: 0;
    }
`;

export const DropzoneImage = styled.img`
    width: 100%;
    height: 100%; 
`;