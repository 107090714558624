import styled from 'styled-components';

export const Container = styled.div`
    padding: 1rem 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: var(--color-white);
    height: 13.12rem;
    max-height: 15rem;

    & > p {
        font-size: 1rem;
        color: var(--color-black);
    }

    & > strong {
        font-size: 1.5rem;
        font-weight: bold;
    }
`;
