import React, { InputHTMLAttributes } from 'react';

import styled from 'styled-components';

const GroupInput = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98%;
`

const Label = styled.label`
    font-family: 'moskmedium_500';
    font-size: 1rem;
    width: calc(35%);
    color: var(--color-secondary);
`

const CustomInput = styled.input`
    width: calc(65%);
    height: 2.4rem;
    padding: 0 0.1rem;
    border-radius: 5px;
    color: var(--color-primary);
    ::placeholder {
        color: var(--color-primary)
    }
`

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string,
    label: string,
}
const Input: React.FC<InputProps> = ({ 
    name, 
    label, 
    ...rest
}) => {
    return (
        <GroupInput>
            <Label htmlFor={name}>{label}</Label>
            <CustomInput id={name} name={name} {...rest} />
        </GroupInput>
    )
}

export default Input;