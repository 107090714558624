import styled from 'styled-components'

export {
    ModalStartedContent,
    ModalHalf,
    AmountText,
    ModalRow,
    ModalStartedRowTitle
}

const ModalStartedContent = styled.div`
    display:flex;    
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const ModalHalf = styled.div`
    width:100%;
    height:50%;
`
const AmountText = styled.p`
    display: flex;
    font-size: 1rem;
    margin: 0.15rem 0;
    justify-content: center;
`
const ModalRow = styled.div`
    display:flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    padding-top:1.3rem;
`
const ModalStartedRowTitle = styled.strong`
    display:flex;
    width: 100%;
    font-size:2rem;
    justify-content: center;
    padding-top:0.7rem;
    padding-bot:0.1rem;
`