import styled from 'styled-components';

export const TransactionItemDiv = styled.div`
    display: flex;
    flex-flow: wrap;
    padding: 1rem;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
    margin-top: 1rem;
`


export const TransactionSingleDetailBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 20%;
    height: 5rem;
`

export const TransactionTitleSingleDetail = styled.strong`
    font-size: 1.2rem;
    color: var(--color-gray);

`
export const TransactionDescriptionSingleDetail = styled.span`
    font-size: 1.2rem;
    color: var(--color-strong-gray);
`