import api from "../api"

interface BankAccountFields {
    bank_code: string,
    agency: string,
    agency_dv?: string
    account: string
    account_dv: string
    document_number: string,
    legal_name: string
    type: string
  }

export const save = async (accountData: BankAccountFields) => {
    try {
        const { data } = await api.post("/bank-account", accountData)        
        return data;
    } catch (error) {
        throw error;
    }
}