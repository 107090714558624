import React, { useEffect, useState } from 'react';
import { InfoPanel, MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';
import SettingsItemAuction from '../../components/SettingsItemAuction';
import SettingsSideBar from '../../components/SettingsSideBar';
import Auction from '../../interfaces/Auction';
import { getAbandonedByCustomer } from '../../services/auction';

// import { Container } from './styles';

const CustomerAbandoned: React.FC = () => {
    const [abandoned, setAbandoned] = useState([] as Auction[]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initAbandoned();
    }, [])

    const initAbandoned = async () => {
        setLoading(true);
        try {
            const data = await getAbandonedByCustomer();
            setAbandoned(data);
        } catch (error) {
            console.log("Error")
        }
        setLoading(false);
    }

    return (
        <MainContainer>
            <MainContent>
                <SettingsSideBar />
                <InfoPanel>
                    <h1>Compras</h1>
                    <h2>Abandonados</h2>

                    <h3>Leilões abandonados</h3>
                    {
                        loading ? <GifLoading /> :
                        abandoned.length > 0 ?
                            abandoned.map(auction => <SettingsItemAuction key={auction.id} auction={auction} />)
                        :
                            <p>Nenhum leilão abandonado..</p>
                    }
                </InfoPanel>
            </MainContent>
        </MainContainer>   
  )
}

export default CustomerAbandoned;