import React, { FormEvent, useState, ChangeEvent } from 'react';
import { Container, Content, Form, Logo, LogoImage, Button, Div49, Row } from './style';

import LogoImg from '../../../assets/images/logo.svg';
import Input from '../../../components/Input';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import GifLoading from '../../../components/GifLoading';
import BtnFacebook from '../../../components/BtnFacebook';

interface LoginParams {
    returnUrl: string
}

const Login : React.FC = () => {
    const { signIn } = useAuth();

    const history = useHistory();
    const { state } = useLocation<LoginParams>();

    const [loading, setLoading] = useState(false)
    
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    function handleFormData(e : ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;
        setFormData(f => ({...f, [name]: value}));
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        if (loading) return;

        setLoading(true);
        try {
            await signIn(formData.email, formData.password);
            if (state && state.returnUrl) {
                history.push(state.returnUrl);
            } else {
                history.push('/');
            }
        } catch (error) {
            console.log('=== ERROR LOGIN USER ===', error);
            alert(`Verifique suas credenciais.\n${error.message}`)
        }
        setLoading(false);
    }

    return (
        <Container>
            <Content>
                <Logo>
                    <LogoImage src={LogoImg} alt="Venatu" />
                </Logo>

                <Form onSubmit={handleSubmit}>
                    <Input 
                        label="E-mail" 
                        name="email"
                        type="email" 
                        value={formData.email}
                        onChange={handleFormData}
                        placeholder="seu@email.com" 
                        required={true} 
                    />
                    <Input 
                        label="Senha" 
                        name="password" 
                        type="password" 
                        value={formData.password}
                        onChange={handleFormData}
                        placeholder="Senha" 
                        required 
                    />


                    {
                    loading ? <GifLoading /> :
                        <Row>
                            <Div49>
                                <BtnFacebook />
                            </Div49>
                            <Div49>
                                <Button type="submit">
                                    Acessar
                                </Button>
                            </Div49>
                        </Row>
                    }
                </Form>
            </Content>
        </Container>
    )
}

export default Login;