import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';


const RadioInput = styled.input`
    height: 1rem;
    width: 1rem;
    position: absolute;

    &:checked:before {
        background-color: var(--color-secondary);
        width: 100%;
        height: 100%;
        border-radius: 15px;
        position: relative;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid var(--color-gray);
    }

`

const RadioLabel = styled.label`
    color: var(--color-gray);
    font-size: 1rem;

    & + & {
        margin-left: 1rem;
    }

    & > span {
        padding-left: 1.5rem;
    }

`

interface VenatuRadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string
}

const VenatuRadioButton : React.FC<VenatuRadioButtonProps> = ({
    label,
    ...props
}) => {
    return (
        <RadioLabel>
            <RadioInput type="radio" {...props} />
            <span>{label}</span>
        </RadioLabel>
    )
}

export default VenatuRadioButton;