import React from 'react';
import styled from 'styled-components';

interface InfoDetailProductProps {
    label: string,
    value: string
}

const ContainerInfo = styled.div`
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.45rem 0; 
`

const Label = styled.span`
    color: var(--color-gray);
    font-size: 1rem;
    margin-bottom: 10rem;
`

const Value = styled.p`
    color: #000;
    font-size: 1.4rem;
    font-family: var(--font-secondary-medium);
    font-weight: bold;
    text-align: justify;
`
const InfoDetailProduct : React.FC<InfoDetailProductProps> = ({
    label,
    value
}) => {
    return (
        <ContainerInfo>
            <Label>{label}</Label>
            <Value>{value}</Value>
        </ContainerInfo>
    )
}

export default InfoDetailProduct;