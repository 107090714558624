import styled from 'styled-components';

export const ProductSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ProductImage = styled.img`
  width: 6.25rem;
  height: 6.25rem;
`;

export const ProductDetail = styled.div`
  margin-left: 1rem;
  display: block;
  
  & > * + * {
    margin-top: 1rem;
  }

  & > p {
    font-size: 1rem;
  }
`

export const Title = styled.h1`
  color: var(--color-strong-gray);
  font-size: 1.62rem;
  
`