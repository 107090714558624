import styled from 'styled-components'

export {
    Title,
    Dropdown,
    Dropzone,
    Button,
    TextArea,
    ProductRegistrationContent,
    ProductSpecContainer,
    Input,
    ProductTitle,
    Radio,
    Column,
    Description,
    ContinueBackContainer,
    TextAreaDescription, TitleDescription,CategoryRegistrationContent,
    Row
}

const Row = styled.div<({ patternWidth? : string })>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: ${(props: any) => props.patternWidth || '100%'};
`

export const RowStart = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: ${(props: any) => props.patternWidth || '100%'};

    & > div + div {
        margin-left: 1rem;
    }
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const ProductRegistrationContent = styled.div`
    display:flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    & > div, & > p {
        margin: 1.25rem 0; 
    }
`

const CategoryRegistrationContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    & > * {
        margin: 1.25rem 0; 
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 28.12rem;
    }
`


const ProductSpecContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap:  wrap;
    flex-direction: row;
    div {
        width: 32%;
        display:flex;
    }

    div:last-child {
        width: 100%;
    }
`

const ProductTitle = styled.p`
    font-family: var(--font-secondary);
    font-weight:bold;
`

const Radio = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    
`

const Title = styled.p`
    max-width: 100vw;
    display:flex;
    font-family: var(--font-secondary-medium);
    font-size:1.62rem;
    color: var(--color-strong-gray);

    & + small {
        font-family: var(--font-secondary-medium);
        font-size: 1rem;
        color: var(--color-gray);
    }
`

const TitleDescription = styled.span`
    font-family: var(--font-secondary);
    font-weight: bold;
    font-size: 1rem;
    margin: 0rem;
    line-height: 1.32rem;
`

const TextAreaDescription = styled.textarea`
    font-family: var(--font-secondary-medium);
    font-size: 0.9rem;
    padding: 1rem;
    border: 0.5px solid var(--color-gray);
    border-radius: 0.3rem;
    min-height: 8.75rem;
`

const TextArea = styled.textarea`
    height:10rem;    
    font-family: var(--font-secondary);
`

const Button = styled.button`
    background-color: var(--color-secondary);
`

const Dropdown = styled.select``

const Dropzone = styled.div``

const Input = styled.input`
    width: 3rem;
`
const Description = styled.div`
    flex-direction: column;
    display:flex;
    flex-wrap:wrap;
    width: 100%;
`

const ContinueBackContainer = styled.div`
    justify-content:flex-end;
    display:flex;
    flex-direction: row;
    width:100%;
`