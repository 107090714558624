import React from 'react';
import { MainContainer, MainContent } from '../../assets/GlobalStyles';
import { NotFoundContainer, Title404, Description404, Message404 } from './style';


function NotFound() {
    return (
        <NotFoundContainer>
            <Title404>404</Title404>
            <Description404>Página não encontrada</Description404>
            <Message404>O conteúdo que você está buscando está indisponível ou não existe.</Message404>
        </NotFoundContainer>
    )
}

export default NotFound;