import React, { useEffect, useState } from 'react';
import { InfoPanel, MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';
import ItemProduct from '../../components/ItemProduct';
import ListPagination from '../../components/ListPagination';
import SettingsSideBar from '../../components/SettingsSideBar';
import Product from '../../interfaces/Product'
import { getProductsBySeller } from '../../services/product';

const SellerProducts: React.FC = () => {
    const [products, setProducts] = useState<Product & { state: "USED" | "NEW" }[]>();
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadProducts();
    }, [])

    useEffect(() => {
        loadProducts();
    }, [currentPage])

    async function  loadProducts() {
        setLoading(true);
        try {
            const { data, count } = await getProductsBySeller({ page: currentPage, limit: 5 });
            const serializedProducts = data.map((prod: any) => ({
                ...prod,
                title: prod.product.title,
                imageUrl: prod.product.imageUrl,
                id: prod.product.id,
                state: prod.state
            }))
            setProducts(serializedProducts);
            setCount(count | 0);    
        } catch (error) {
            console.log("Error...")
        }
        setLoading(false);
        
    }

    async function onPaginationClick(page: number) {
        setCurrentPage(page);
        loadProducts();
    }

    return (
        <MainContainer>
            <MainContent>
                <SettingsSideBar />
                <InfoPanel>
                    <h1>Vendas</h1>
                    <h2>Produtos [{count}]</h2>

                    <div>
                        {
                            loading ? <GifLoading /> :
                            products && products.length > 0 ?
                            <>
                                {products.map((product: any) => 
                                    <ItemProduct 
                                        showcaseText={"Preço de vitrine"}
                                        product={product} 
                                        key={product.id} 
                                    />
                                )}

                                <ListPagination
                                    initialPage={currentPage}
                                    pageClick={onPaginationClick}
                                    totalRecords={count}
                                />
                            </>
                            :
                            <p>Você não tem produtos cadastrados</p>
                        }
                        
                    </div>
                </InfoPanel>
            </MainContent>
        </MainContainer>
    );
}

export default SellerProducts;