import api from '../api';
import { RegisterUserFields } from '../../pages/Auth/Register';

export const signIn = (email: string, password: string) =>  {
    const params = {
        email,
        password
    }
    return api.post('auth/login', params);
}

export const signOut = () =>  {
    return api.post('auth/logout');
}

export const register = (params : RegisterUserFields) => {
    return api.post('/auth/register', params);
}

export const signInWithFacebook = (user : any) => {
    return api.post('/auth/loginFacebook', user);
}

export const loadMe = () => {
    return api.get('/auth/me');
}