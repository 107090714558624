import React from 'react';
import "./styles.tsx";

import {  CustomModalContainer, Title, Message , CustomModalContent, CustomModalHeader, CustomModalBody, CustomModalFooter} from './styles';
import Modal from 'react-modal';
import VenatuButton from '../VenatuButton';


interface CustomModalProps{
    onClick? : () => void
    onClose? : () => void
    modalIsOpen: boolean
    modalTimeout?: number
    id?:string
}

const CustomModal : React.FC<CustomModalProps> = ({ 
    children, 
    onClose,
    onClick,
    modalIsOpen,
    modalTimeout,
    id
}) => {
        return (
            <Modal isOpen={modalIsOpen} onRequestClose={onClose} closeTimeoutMS={modalTimeout || 200 } id={id}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    zIndex:100,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
               
                },
                content: {
                    position: "relative",
                  border: '1px solid #ccc',
                  overflow: 'hidden',
                  WebkitOverflowScrolling: 'touch',
                  borderRadius: '4px',
                  outline: 'none',
                  padding: '0px',
                  zIndex:100,
                  width:'35.9375rem',
                  height:'25rem'
                }
              }}>
                <CustomModalContainer >
                    <CustomModalHeader>
                        <Title> Iniciando Leilão</Title>
                    </CustomModalHeader>
                    <CustomModalBody>
                        {children}
                    </CustomModalBody>
                    <CustomModalFooter>
                        <VenatuButton bgColor="#fff" onClick= {onClose} >Cancelar</VenatuButton>
                        <VenatuButton onClick={onClick}>Confirmar</VenatuButton> 
                    </CustomModalFooter>
                </CustomModalContainer>
            </Modal>
        )
    }
    
export default CustomModal;