import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    align-self: center;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: wrap;

    width: 80%;
    background: var(--color-white);
    padding: 2rem;
    margin: 2.5rem 0;

    & > span {
        margin-top: 0.5rem;
        color: var(--color-secondary);
        font-size: 1rem;
    }

    & > h2 {
        width: 100%;
        font-size: 1.5rem;
        margin: 2rem 0;
    }

    & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    & > input {
        width: 49%;
        height: 2.85rem;
        padding: 1rem;
        border-radius: 5px;
        color: var(--color-primary);
        border: 1px solid var(--color-gray);
        font-size: 1.2rem;
        ::placeholder {
            color: var(--color-gray);
            font-size: 1.2rem;
        }
    }

    & > input, select + input {
        margin-top: 1.5rem;
    }

    & > input[name='cpf'] {
        width: 49%;
    }

    & > input[name='birthday'], input[name='number'], input[name='complement'] {
        width: 23%;
    }

    & > input[name='email'] {
        width: 100%;
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center;
    width: 100%;
    margin-top: 2rem;

    & > span {
        margin-left: 2rem;
        width: 60%;
        text-align: justify;
        color: var(--color-gray);

        & > strong {
            font-weight: bold;
            color: var(--color-primary)
        }
    }
`

export const RegisterInput = styled.input<{width?: string}>`
    width: ${props => props.width || '49%'};
    height: 2.85rem;
    padding: 1rem;
    border-radius: 5px;
    color: var(--color-primary);
    border: 1px solid var(--color-gray);
    font-size: 1.2rem;
    margin-top: 1.5rem;

    ::placeholder {
        color: var(--color-gray);
        font-size: 1.2rem;
    }
`

export const RegisterSelect = styled.select<{width?: string}>`
    width: ${props => props.width || '49%'};
    height: 2.85rem;
    padding-left: 1rem;
    border-radius: 5px;
    color: var(--color-primary);
    border: 1px solid var(--color-gray);
    background: transparent;
    font-size: 1.2rem;
    margin-top: 1.5rem;

    ::placeholder {
        color: var(--color-gray);
        font-size: 1.2rem;
    }
`

export const DontKnowZipCode = styled.a`
    cursor: pointer;
    text-align: left;
    margin-top: 2rem;
    height: 2.5rem;
    margin-left: 1rem;
    font-size: 1.2rem;
    color: var(--color-secondary);
    font-weight: bold;
    background: transparent;
    text-align: left;
    border: none;
`

export const DivCepLoading = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 49% !important;

    & > span {
        margin-top: 1.5rem;
    }
`