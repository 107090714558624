import React, { ButtonHTMLAttributes } from 'react';
import { Button } from './style';

interface VenatuButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    onClick? : () => void
    bgColor?: string
    txtColor?: string,
    ml?: string,
    fs?: string,
    width?: string
    mt?: string
}



const VenatuButton : React.FC<VenatuButtonProps> = ({ 
    children, 
    onClick,
    bgColor = undefined,
    txtColor = undefined,
    ml = undefined,
    mt = undefined,
    fs = undefined,
    width = undefined,
    ...props
} : VenatuButtonProps) => {
    return (
        <Button
            {...props} 
            bgColor={bgColor}
            txtColor={txtColor}
            onClick={onClick}
            ml={ml}
            fs={fs}
            mt={mt}
            width={width}
        >
            {children}
        </Button>
    )
}

export default VenatuButton