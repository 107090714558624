import React, { ChangeEvent, FocusEvent, FormEvent, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';
import VenatuButton from '../../components/VenatuButton';
import { PriceInfoSection, PurchaseIdText, PurchaseInfoSection,  PurchaseInfoTitle, PurchaseEconomyText, PurchaseTitleText,  PaymentForm, PurchaseMainAddressText, PurchaseSecondaryAddressText, PurchaseInternLink, HistoryTransaction } from './styles';
import { findByAuctionId } from '../../services/purchase';
import Purchase from '../../interfaces/Purchase';
import CurrencyText from '../../components/CurrencyText';
import { DivCepLoading, DontKnowZipCode, RegisterInput, RegisterSelect } from '../Auth/Register/style';
import ReactInputMask from 'react-input-mask';
import { getAddressInfoByCep } from '../../services/correios/service';
import { IBGEEstado, initCitys, initUfs } from '../../services/ibge/service';
import Cards from 'react-credit-cards';
import '../../../node_modules/react-credit-cards/es/styles-compiled.css'
import { formatProductToRouteParams } from '../../util/formatProductToRouteParams';
import { checkout } from '../../services/checkout';
import TransactionItem from '../../components/TransactionItem';

interface PurchaseAuctionParams {
    id: string,
}

export interface CardFields {
    cardCvc: string,
    cardExpiry: string,
    cardFocus: 'name' | 'cvc' | 'number' | 'expiry' | undefined,
    cardName: string,
    cardNumber: string,
    isValid: boolean
}
const PurchaseAuction = () => {
    const { id } = useParams<PurchaseAuctionParams>();
    const [purchase, setPurchase] = useState<Purchase>({} as Purchase)
    const [loading, setLoading] = useState(false);
    const [loadingCep, setLoadingCep] = useState(false);
    const [sending, setSending] = useState(false);
    const [changingAddress, setChangingAddress] = useState(false);
    const [installments, setInstallments] = useState(1);

    const [citys, setCitys] = useState<string[]>([]); 
    const [ufs, setUfs] = useState<IBGEEstado[]>([]); 

    const [card, setCard] = useState<CardFields>({
        cardCvc: '',
        cardExpiry: '',
        cardFocus: 'name',
        cardName: '',
        cardNumber: '',
        isValid: false
    })

    const loadUfs = initUfs;
    const loadCitys = initCitys

    useEffect(() => {
        loadUfs().then(values => {
            setUfs(values)
        })
    }, [])

    useEffect(() => {
        loadCitys(purchase.uf).then(values => {
            if (values.length > 0) {
                setCitys(values);
            }
        })
    }, [purchase.uf])
    

    useEffect(() => {
        initPurchase()
    }, [id])

    async function initPurchase () {
        setLoading(true);
        try {
            const data = await findByAuctionId(Number(id));
            setPurchase(data);
        } catch (error) {
            console.log("Error")
        }
        setLoading(false);
    }

    const handleFormData = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        if (name === 'streetNumber') {
            setPurchase(p => ({...p, number: value}))
        } else {
            setPurchase(p => ({...p, [name]: value }))
        }
    }

    const handleBlurCep = async (cep: string) => {
        setLoadingCep(true);
        try {
            const data = await getAddressInfoByCep(cep);
            setPurchase(f => ({
                ...f,
                ...data
            }))
        } catch (error) {
            console.log("Error on getAddressInfoByCep", error)
        }
        setLoadingCep(false);
    }

    const handleAddressSubmit = (e : FormEvent) => {
        e.preventDefault();

        setChangingAddress(false);
    }

    const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
        const { name } = e.target;

        if (name === 'cardCvc') {
            setCard(cs => ({...cs, cardFocus: 'cvc' }));
        } else if (name === 'cardExpiry') {
            setCard(cs => ({...cs, cardFocus: 'expiry' }));
        } else if (name === 'cardNumber') {
            setCard(cs => ({...cs, cardFocus: 'number' }));
        } else if (name === 'cardName') {
            setCard(cs => ({...cs, cardFocus: 'name' }));
        }

    }
      
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        
        setCard(card => ({...card, [name]: value.toUpperCase() }));
    }

    const handleCallbackCard = (info: any, isValid: boolean) => {
        setCard(card => ({...card, isValid }))
    }

    const handlePaymentFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (sending) return;
        if (!card.isValid) {
            alert("Cartao de crédito inválido");
            return;
        }

        const data = {
            ...purchase,
            ...card,
            installments
        }

        setSending(true)
        try {
            await checkout(purchase.id, data)
            await initPurchase();
        } catch (error) {
            alert("Verifique seus dados e tente novamente");
        }
        setSending(false);
    }

    return (
        <MainContainer>
            {
                loading || !purchase ? <GifLoading /> : 
            <MainContent>
                <PurchaseInfoSection>
                    <PurchaseIdText>Compra #{purchase.id}</PurchaseIdText>

                    <div>
                        <PurchaseTitleText>
                            {purchase.offer?.auction?.product.title}
                            <Link to={`/products/${formatProductToRouteParams(purchase.offer?.auction?.product, purchase.offer?.auction?.state)}`}>
                                Ver detalhes
                            </Link>
                        </PurchaseTitleText>
                        <PurchaseIdText>Valor alcançado: <CurrencyText value={purchase.price} /></PurchaseIdText>
                        <PurchaseEconomyText>Você economizou: <CurrencyText value={purchase.offer?.auction?.initialBid - purchase.price} /></PurchaseEconomyText>
                    </div>

                    <div>
                        <PurchaseInfoTitle>Vendido por</PurchaseInfoTitle>
                        <PurchaseMainAddressText>
                            {purchase.offer?.seller?.first_name}
                        </PurchaseMainAddressText>
                        <PurchaseSecondaryAddressText>
                            <p>CNPJ: {purchase.offer?.seller?.cnpj}</p>
                            <p>Telefone: {purchase.offer?.seller?.user?.phone}</p>
                            <p>Email: {purchase.offer?.seller?.user?.email}</p>
                        </PurchaseSecondaryAddressText>
                    </div>

                    <PurchaseInfoTitle>Endereço de entrega</PurchaseInfoTitle>

                    {
                        changingAddress ? 
                        <form onSubmit={handleAddressSubmit}>
                            <div>
                            <ReactInputMask 
                                mask="99999-999"
                                name="zipcode"
                                placeholder="CEP"
                                value={purchase.zipcode}
                                onChange={handleFormData}
                                onBlur={({ target }) => handleBlurCep(target.value)}
                                required
                            />

                            <DivCepLoading>
                                {   
                                    loadingCep ? <span style={{width: '50px !important'}}><GifLoading /></span> :
                                    <DontKnowZipCode href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_blank">
                                        Não sei meu CEP
                                    </DontKnowZipCode>
                                }
                            </DivCepLoading>
                            </div>

                            <RegisterInput
                                name="street"
                                placeholder="Endereço"
                                value={purchase.street}
                                onChange={handleFormData}
                                required
                            />
                            <RegisterInput
                                width="23%"
                                name="streetNumber"
                                value={purchase.number}
                                onChange={handleFormData}
                                placeholder="Número"
                                required
                            />
                            <RegisterInput
                                width="23%"
                                name="complement"
                                value={purchase.complement}
                                onChange={handleFormData}
                                placeholder="Complemento"
                            />
                            <RegisterInput
                                name="neighbor"
                                value={purchase.neighbor}
                                onChange={handleFormData}
                                placeholder="Bairro"
                                required
                            />

                            <RegisterSelect 
                                required 
                                defaultValue="" 
                                width="23%"
                                value={purchase.city}
                                name="city"
                                onChange={handleFormData}
                            >
                                <option disabled value="">Cidade</option>
                                {
                                citys && citys.map((city: any) => (
                                    <option key={city} value={city}>{city}</option>
                                ))   
                                }
                            </RegisterSelect>
                            <RegisterSelect 
                                required 
                                defaultValue="" 
                                width="23%"
                                value={purchase.uf}
                                name="uf"
                                onChange={handleFormData}
                            >
                                <option disabled value="">Estado</option>
                                {
                                ufs && ufs.map((uf: any) => (
                                    <option key={uf.sigla} value={uf.sigla}>{uf.nome}</option>
                                ))   
                                }
                            </RegisterSelect>

                            <VenatuButton   ml={"0px"} mt="1rem" txtColor="var(--color-white)">Salvar</VenatuButton>
                        </form>
                        :
                        <div>
                            <PurchaseMainAddressText>
                                {purchase.street}, 
                                nº {purchase.number}
                            </PurchaseMainAddressText>
                            <PurchaseSecondaryAddressText>
                                {
                                `${purchase.complement && (purchase.complement + ' - ')}
                                ${purchase.neighbor}, 
                                ${purchase.city}/${purchase.uf}.
                                CEP: ${purchase.zipcode}`
                                }
                            </PurchaseSecondaryAddressText>
                            {
                                purchase.finished == false && 
                                    <PurchaseInternLink onClick={() => setChangingAddress(true)}>Enviar para outro endereço</PurchaseInternLink>
                            }
                        </div>

                    }

                    {
                        purchase.finished == false && (
                            <>
                            <PurchaseInfoTitle>Pagamento</PurchaseInfoTitle>

                            <PaymentForm onSubmit={handlePaymentFormSubmit}>
                                <Cards 
                                    cvc={card.cardCvc}
                                    expiry={card.cardExpiry}
                                    focused={card.cardFocus}
                                    name={card.cardName}
                                    number={card.cardNumber}
                                    placeholders={{
                                        name: "SEU NOME",
                                    }}
                                    locale={{
                                        valid: "Validade"
                                    }}
                                    acceptedCards={["visa","mastercard"]}
                                    callback={handleCallbackCard}
                                />
                                <div>
                                    <div>

                                    
                                    <RegisterInput
                                        width="100%"
                                        name="cardName"
                                        value={card.cardName}
                                        placeholder="Nome no cartão"
                                        onFocus={handleInputFocus}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    
                                    <ReactInputMask
                                        mask={"9999999999999999"}
                                        width="100%"
                                        name="cardNumber"
                                        value={card.cardNumber}
                                        placeholder="Número do cartão"
                                        onFocus={handleInputFocus}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    </div>
                                    <div>
                                        <ReactInputMask 
                                            mask="99/99"
                                            width="49%"
                                            name="cardExpiry"
                                            value={card.cardExpiry}
                                            placeholder="Expira em"
                                            onFocus={handleInputFocus}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <ReactInputMask
                                            mask="999"
                                            width="49%"
                                            name="cardCvc"
                                            value={card.cardCvc}
                                            placeholder="CVC"
                                            onFocus={handleInputFocus}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>

                                    <RegisterSelect 
                                        required 
                                        defaultValue="" 
                                        width="100%%"
                                        value={installments}
                                        name="installments"
                                        onChange={(e) => setInstallments(Number(e.target.value))}
                                    >
                                        <option disabled value="">Parcelas</option>
                                        <option value={1}>1x de R$ {String((purchase.price || 0).toFixed(2)).replace('.',',')}</option>
                                        <option value={2}>2x de {String((purchase.price / 2 || 0).toFixed(2)).replace('.',',')}</option>
                                        <option value={3}>3x de {String((purchase.price / 3 || 0).toFixed(2)).replace('.',',')}</option>
                                        <option value={4}>4x de {String((purchase.price / 4 || 0).toFixed(2)).replace('.',',')}</option>
                                        <option value={5}>5x de {String((purchase.price / 5 || 0).toFixed(2)).replace('.',',')}</option>
                                        <option value={6}>6x de {String((purchase.price / 6 || 0).toFixed(2)).replace('.',',')}</option>
                                    </RegisterSelect>
                                    
                                    <div>
                                        {
                                            sending ? <GifLoading /> :
                                                <VenatuButton
                                                    width="100%"
                                                    ml="0px"
                                                    txtColor={"var(--color-white)"}
                                                    >
                                                        Finalizar
                                                </VenatuButton>
                                    }
                                    </div>
                                </div>
                            </PaymentForm>
                            </>
                        )
                    }

                    {
                        purchase.transactions && purchase.transactions.length > 0 &&
                            <>
                            <PurchaseInfoTitle>Histórico de transações</PurchaseInfoTitle>

                            <HistoryTransaction>
                                {
                                    purchase.transactions.map(transaction => (
                                        <TransactionItem
                                            key={transaction.id}
                                            transaction={transaction}
                                        />
                                    ))
                                }
                            </HistoryTransaction>
                            </>
                    }
            </PurchaseInfoSection>
                <PriceInfoSection>
                    <strong>Subtotal</strong>

                    <div>
                        <p>Produto <CurrencyText value={purchase.price} /></p>
                        <p>Envio <span>---</span></p>
                        <h3>TOTAL: <CurrencyText value={purchase.price} /></h3>
                    </div>
                </PriceInfoSection>
            </MainContent>
            }
        </MainContainer>
    )
}

export default PurchaseAuction