import api from "../api";

export const getCurrentBySeller = async () => {
    try {
        const { data } = await api.get('/offers-seller');
        return data;
    } catch (error) {
        console.log("===    ERROR ON getCurrentBySeller ====")
        console.log(error);
        throw error;
    }
}

export const abandonOffer = async (id: number) => {
    try {
        await api.put(`/offers/${id}/abandon`)
    } catch (error) {
        console.log('===    ERROR ON abandon   ===')
        console.log(error)
        throw error
    }
}