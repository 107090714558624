import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import CurrencyFormat from 'react-currency-format';

interface VenatuCurrencyProps extends InputHTMLAttributes<HTMLInputElement> {
    id? : string,
    label?: string,
    value: string,
    allowNegative?: boolean
    onChangeGetFloatValue?: (value: number) => void
    onChangeGetFormattedValue?: (value: string) => void
    onChangeGetValue?: (value: string) => void
}

const ContainerInfo = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.45rem 0; 

    & > input {
        font-family: var(--font-secondary-medium);
        font-size: 0.9rem;
        padding: 1rem;
        border: 0.5px solid var(--color-gray);
        border-radius: 0.3rem;
    }
`

const Label = styled.label`
    font-family: var(--font-secondary);
    font-weight: bold;
    font-size: 1rem;
    margin: 0rem;
    line-height: 1.32rem;
`

const VenatuCurrency : React.FC<VenatuCurrencyProps> = ({
    id, 
    label,
    onChangeGetFloatValue,
    onChangeGetFormattedValue,
    onChangeGetValue,
    allowNegative,
    ...props
}) => {

    const customOnChange = (values: { floatValue: number, formattedValue: string, value: string }) => {
        const { floatValue, formattedValue, value } = values;

        if (onChangeGetFloatValue) onChangeGetFloatValue(floatValue);
        if (onChangeGetFormattedValue) onChangeGetFormattedValue(formattedValue);
        if (onChangeGetValue) onChangeGetValue(value);
    }

    return (
        <ContainerInfo>
            <Label htmlFor={id}>{label}</Label>
            <CurrencyFormat 
                id={id} 
                prefix={"R$ "} 
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={allowNegative || false}
                onValueChange={customOnChange}
                { ...props } 
            />
        </ContainerInfo>
    )
}

export default VenatuCurrency;