import React from 'react';
import { BannerDiv } from './style';
import BannerImg from '../../../assets/images/carr1.png';

const Banner :React.FC = () => {
    return(
        <BannerDiv src= {BannerImg}></BannerDiv>
    )

}
export default Banner;