import React, { useEffect, useState } from 'react';
import { InfoPanel, MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';
import SettingsItemPurchase from '../../components/SettingsItemPurchase';
import SettingsSideBar from '../../components/SettingsSideBar';
import Purchase from '../../interfaces/Purchase';
import { getFinishBySeller } from '../../services/purchase' 

const SellerFinish: React.FC = () => {
    const [finish, setFinish] = useState([] as Purchase[])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        initFinish()
    }, [])

    const initFinish = async () => {
        setLoading(true)
        try {
            const finishs = await getFinishBySeller();
            setFinish(finishs);
        } catch (error) {
            console.log("Error")
        }
        setLoading(false)
    }

    return (
        <MainContainer>
            <MainContent>
                <SettingsSideBar />
                <InfoPanel>
                    <h1>Vendas</h1>
                    <h2>Arrematados</h2>

                    <h3>
                        Vendas arrematadas
                    </h3>
                    
                    {
                        loading ? <GifLoading /> :
                        finish.length > 0 ?
                            finish.map(purchase => <SettingsItemPurchase key={purchase.id} purchase={purchase} />)
                        :
                            <p>Nenhuma venda arrematada.</p>
                    }
                </InfoPanel>
            </MainContent>
        </MainContainer>    
    );
}

export default SellerFinish;