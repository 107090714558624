import axios from 'axios';

const correios = axios.create({
    baseURL: 'https://viacep.com.br'
})

const getAddressByCEP = async (cep: string) => {
    try {
        const { data } = await correios.get(`ws/${cep}/json/`)
        return data;
    } catch (error) {
        console.log("Error...")
    }
}

export {
    getAddressByCEP
}