import styled from 'styled-components'

export const Auctions = styled.div`
    
    max-width: 100vw;
    background-color: #F2F2F2;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 1.2rem;
    overflow: hidden;   
    padding-bottom: 2rem;
`


export const AuctionsLayer = styled.div`
    max-height:60%;
    display:flex;
    flex-flow: wrap;
    width:100%;
    justify-content: space-between;
`

export const AuctionDiv = styled.div`
    width: 20%;
    max-width: 12rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    padding: 1.2rem 3rem;
    box-shadow: var(--color-gray) 0px 1px 1px;

    & > strong {
        color: var(--color-primary);
        font-size: 1.4rem
    }

    & > a {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
`

export const AuctionTitle = styled.p`
    color: var(--color-secondary);
    text-align: center;
    font-size: 1.2rem;
`

export const AuctionImage = styled.img`
    max-height: 7rem;
    width: 100%;
    @media(max-width: 1100px) {
        max-height: 4rem;
    }

`