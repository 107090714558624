import React from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from '../../pages/ProductRegistration/style';
import VenatuButton from '../VenatuButton';
import { AuctionDetails, ProductInfo, AuctionInfo, Container } from './styles';
import Purchase from '../../interfaces/Purchase';
import CurrencyText from '../CurrencyText';

interface SettingsItemPurchaseProps {
    purchase: Purchase,
}
const SettingsItemPurchase: React.FC<SettingsItemPurchaseProps> = ({ 
    purchase ,
}) => {
    const history = useHistory();

    const handleClickAccessAuction = () => {
        history.push(`/perfil/vendas/${purchase.id}`)
    }

    return (
        <Container>
            <ProductInfo>
                <img src={purchase.offer.auction.product.imageUrl} alt={purchase.offer.auction.product.title} />
                <Column>
                    <strong>{purchase.offer.auction.product.title}</strong>
                    <p>Valor alcançado: <CurrencyText value={purchase.price} /></p>
                </Column>
            </ProductInfo>
            <AuctionDetails>
                <AuctionInfo>
                    <VenatuButton onClick={handleClickAccessAuction} txtColor="#fff">Acessar leilão</VenatuButton>
                </AuctionInfo>
            </AuctionDetails>
        </Container>
  );
}

export default SettingsItemPurchase;
