import React, { useEffect, useState } from 'react';
import Auction from '../../../interfaces/Auction';
import Bid from '../../../interfaces/Bid';
import Offer from '../../../interfaces/Offer';
import { Row } from '../../../pages/Auth/Login/style';
import { addNewBid, findAllBidsByAuctionId } from '../../../services/bids';
import getAuctionsAvailableUntil from '../../../util/getAuctionAvailableUntil';
import BidsTable from '../../BidsTable';
import VenatuButton from '../../VenatuButton';
import VenatuCountDown from '../../VenatuCountDown';
import VenatuCurrency from '../../VenatuCurrency';
import { TableContainer } from '../style';

interface ParticipateBoxProps {
  auction: Auction,
  offer: Offer | undefined,
}

const ParticipateBox: React.FC<ParticipateBoxProps> = ({
  auction,
  offer,
}) => {
  const availableUntil = getAuctionsAvailableUntil(auction);
  
  const [bid, setBid] = useState(-50);
  const [bids, setBids] = useState([] as Bid[]);

  useEffect(() => {
        initBids()
    }, [auction.id])

    const initBids = async () => {
        try {
            const data = await findAllBidsByAuctionId(auction.id)
            setBids(data);
        } catch (error) {
            alert('Erro inesperado!')
        }
    }

  
  const handleSubmitNewBid = async () => {
    if (bid > 0) return;

    const data = {
      bid,
      offerId: offer?.id || -1
    }

    try {
        await addNewBid(data);
        initBids();
    } catch (error) {
        alert('Erro inesperado ao fazer lançamento')
    }
  }

  return (
    <TableContainer>
      {
          auction.available ?
            <header>
              <p>Leilão em andamento</p>
              <strong>Resta <VenatuCountDown date={availableUntil}>finish</VenatuCountDown></strong>  
            </header>
          :
            <header>
              <p>Leilão encerrado</p>
            </header>

      }

      <main>
        <BidsTable bids={bids} offer={offer} auction={auction} />
      </main>

      <footer>
        {
          auction.available  &&
          <Row>
            <VenatuCurrency allowNegative={true} value={String(bid)} onChangeGetFloatValue={setBid} />
            <VenatuButton onClick={handleSubmitNewBid} txtColor={"var(--color-white)"}>Baixar lance</VenatuButton>
          </Row>
        }
      </footer>
    </TableContainer>
  )
}

export default ParticipateBox;
