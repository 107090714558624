import React, { useEffect, useState } from 'react';
import { InfoPanel, MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';
import SettingsItemAuction from '../../components/SettingsItemAuction';
import SettingsItemOffer from '../../components/SettingsItemOffer';
import SettingsSideBar from '../../components/SettingsSideBar';
import Auction from '../../interfaces/Auction';
import Offer from '../../interfaces/Offer';
import { getAvailableBySeller } from '../../services/auction';
import { getCurrentBySeller } from '../../services/offer';

const SellerAuctions: React.FC = () => {
    const [currentOffers, setCurrentOffers] = useState([] as Offer[])
    const [availableAuctions, setAvailableAuctions] = useState([] as Auction[])
    const [loadingCurrent, setLoadingCurrent] = useState(true);
    const [loadingAvailable, setLoadingAvailable] = useState(true);

    useEffect(() => {
        initCurrents()
        initAvailables()
    }, [])

    const initCurrents = async () => {
        setLoadingCurrent(true)
        try {
            const auctions = await getCurrentBySeller();
            setCurrentOffers(auctions);
        } catch (error) {
            console.log("Error")
        }
        setLoadingCurrent(false)
    }

    const initAvailables = async () => {
        setLoadingAvailable(true)
        try {
            const auctions = await getAvailableBySeller();
            setAvailableAuctions(auctions);
        } catch (error) {
            console.log("Error")
        }
        setLoadingAvailable(false)
    }

    return (
        <MainContainer>
            <MainContent>
                <SettingsSideBar />
                <InfoPanel>
                    <h1>Vendas</h1>
                    <h2>Lances</h2>

                    <h3>Lances em andamento</h3>
                    {
                        loadingCurrent ? <GifLoading /> :
                        currentOffers.length > 0 ?
                            currentOffers.map(offer => <SettingsItemOffer onRefresh={initCurrents} key={offer.id} offer={offer} />)
                        :
                            <p>Você não está participando de nenhum lance.</p>
                    }
                    <h3>Lances disponíveis</h3>
                    {
                        loadingAvailable ? <GifLoading /> :
                        availableAuctions.length > 0 ?
                            availableAuctions.map(auction => <SettingsItemAuction key={auction.id} auction={auction} />)
                        :
                            <p>Não há lances disponíveis pra você no momento.</p>
                    }
                </InfoPanel>
            </MainContent>
        </MainContainer>    
    );
}

export default SellerAuctions;