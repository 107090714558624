import React from 'react';
import { useHistory } from 'react-router-dom';
import Offer from '../../interfaces/Offer';
import { Column } from '../../pages/ProductRegistration/style';
import getAuctionsAvailableUntil from '../../util/getAuctionAvailableUntil';
import VenatuButton from '../VenatuButton';
import { abandonOffer } from '../../services/offer';
import { AuctionDetails, ProductInfo, AuctionInfo, Container } from './styles';

interface SettingsItemOfferProps {
    offer: Offer,
    onRefresh: () => void
}
const SettingsItemOffer: React.FC<SettingsItemOfferProps> = ({ 
    offer ,
    onRefresh
}) => {
    const now = new Date();
    const history = useHistory();

    const handleClickAccessAuction = () => {
        history.push(`/perfil/leilao/${offer.auction.id}`)
    }

    const handleAbandon = async () => {
        try {
            await abandonOffer(offer.id);
            onRefresh();
        } catch (error) {
            alert("Erro inesperado!")
        }
    }

    return (
        <Container>
            <ProductInfo>
                <img src={offer.auction.product.imageUrl} alt={offer.auction.product.title} />
                <Column>
                    <strong>{offer.auction.product.title}</strong>
                </Column>
            </ProductInfo>
            <AuctionDetails>
                <AuctionInfo>
                    <VenatuButton onClick={handleClickAccessAuction} txtColor="#fff">Acessar leilão</VenatuButton>
                    {
                        (getAuctionsAvailableUntil(offer.auction) > now && !offer.abandoned) && 
                            <VenatuButton onClick={handleAbandon} bgColor="#fff" txtColor="var(--color-primary)">Abandonar leilão</VenatuButton>
                    }
                </AuctionInfo>
            </AuctionDetails>
        </Container>
  );
}

export default SettingsItemOffer;
