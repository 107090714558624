import React, { SelectHTMLAttributes } from 'react';
import styled from 'styled-components';
import VenatuDropdown from '../VenatuDropdown';

interface ProductSpecDropdownProps extends SelectHTMLAttributes<HTMLSelectElement> {
    label: string,
}

const ContainerInfo = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.45rem 0; 

`

const Label = styled.label`
    font-family: var(--font-secondary);
    font-weight: bold;
    font-size: 1rem;
    margin: 0rem;
    line-height: 1.32rem;
`

const ProductSpecDropdown : React.FC<ProductSpecDropdownProps> = ({
    id,
    label,
    children,
    ...props
}) => {
    return (
        <ContainerInfo>
            <Label htmlFor={id}>{label}</Label>
            <VenatuDropdown id={id} {...props}>
                {children}
            </VenatuDropdown>
        </ContainerInfo>
    )
}

export default ProductSpecDropdown;