import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

interface ProductSpecProps extends InputHTMLAttributes<HTMLInputElement> {
    id? : string,
    label: string,
    value: string
}

const ContainerInfo = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.45rem 0; 

`

const Label = styled.label`
    font-family: var(--font-secondary);
    font-weight: bold;
    font-size: 1rem;
    margin: 0rem;
    line-height: 1.32rem;
`

const Value = styled.input`
    font-family: var(--font-secondary-medium);
    font-size: 0.9rem;
    padding: 1rem;
    border: 0.5px solid var(--color-gray);
    border-radius: 0.3rem;
`

const ProductSpecStandart : React.FC<ProductSpecProps> = ({
    id, 
    label,
    ...props
}) => {
    return (
        <ContainerInfo>
            <Label htmlFor={id}>{label}</Label>
            <Value {...props} id={id}  />
        </ContainerInfo>
    )
}

export default ProductSpecStandart;