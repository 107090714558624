import styled from "styled-components";
import { ButtonHTMLAttributes } from "react";


export {
    Button
}

interface PropsVenatuButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    bgColor?: string,
    txtColor?: string,
    ml?: string,
    fs?: string,
    width?: string
    mt?: string
}

const Button = styled.button<PropsVenatuButton>`
    height: 2.6rem;
    text-align: center;
    border-radius: 5px;
    box-shadow: var(--color-gray) 0px 0px 0px;
    cursor: pointer;

    width: ${props => props.width || '8.75rem'};
    font-size: ${props => props.fs || '1rem'};
    margin-left: ${props => props.ml || '1.2rem'};
    margin-top: ${props => props.mt || '0ren'};
    background-color: ${props => props.bgColor || 'var(--color-secondary)'};
    color: ${props => props.txtColor || 'var(--color-primary);'};
`