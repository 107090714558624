import styled from 'styled-components';

export const Section = styled.div`
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    span {
        color: var(--color-strong-gray);
        margin-top: 0.5rem;
    }
`;

export const Title = styled.strong`
    color: var(--color-strong-gray);
    font-weight: bold;
`

export const LinkTitle = styled.span<{ active: boolean }>`
    a {
        color: ${props => props.active ? `var(--color-primary)` : 'var(--color-strong-gray)'};
    }
`
