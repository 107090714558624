import React from 'react';

interface TextProps {
    value: number;
}
const CurrencyText:React.FC<TextProps> = ({ value }) => {
    return <span>R$ {String((value || 0).toFixed(2)).replace('.',',')}</span>
    
}

export default CurrencyText;