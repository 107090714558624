import ibge from '../ibge';

export interface IBGEEstado {
    sigla: string,
    nome: string
}

interface IBGECidade {
    nome: string,
}

export const initUfs = async () : Promise<IBGEEstado[]> => {
    const params = {
        orderBy: 'nome'
    }

    try {
        const { data } = await ibge.get(`estados`, { params })
        const serialized = data.map(({ sigla, nome }: IBGEEstado) => {
            return { sigla, nome }
        });        

        return serialized;
    } catch (error) {
        console.log("Error init ufs...")
        return [];
    }
}

export const initCitys = async (uf: string) : Promise<string[]> => {
    const params = {
        orderBy: 'nome'
    }

    try {
        const { data } = await ibge.get(`estados/${uf}/municipios`, { params })
        const serialized: string[] = data.map((city: IBGECidade) => city.nome);        
        return serialized;
    } catch (error) {
        console.log("Error init ufs...")
        return [];
    }
}