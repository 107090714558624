import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import Product from '../../interfaces/Product';
import * as Style from './styles';
import * as ModalStartedStyle from './startedAuctionStyles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '../Breadcrumb';
import VenatuButton from '../VenatuButton';
import CurrencyText from '../CurrencyText';
import ModalStartAuction from '../ModalStartAuction';
import VenatuRadioButton from '../VenatuRadioButton';
import Auction from '../../interfaces/Auction';
import { saveAuction } from '../../services/auction';
import ProductState from '../ProductState';
import ModalAuctionStarted from '../ModalAuctionStarted';
import GifLoading from '../GifLoading';
import IProduct from '../../interfaces/Product';
import { useAuth } from '../../contexts/auth';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { formatProductToRouteParams } from '../../util/formatProductToRouteParams';
import queryString from 'query-string';

interface MainProductProps {
    product: Product
}

const ProductMainView : React.FC<MainProductProps> = ({
    product
}) => {
    const [auction, setAuction] = useState({
    } as Auction)

    const [selectedImage, setSelectedImage] = useState({
        index: 0,
        url: product.imageUrl
    })

    const [loading, setLoading] = useState(true);
    const { buyerProfile } = useAuth();
    
    useEffect(() => {
        setSelectedImage({index: 0, url: product.imageUrl});
        setAuction(auction => ({
            ...auction,
            productId: product.id
        }))
        setLoading(product === {} as IProduct);
    }, [product])


    function handleArrowClick(direction: number) {
        const index = selectedImage.index + direction;
        const images = product.images;
        const lastIndex = images.length - 1;
        
        if (index > lastIndex) {
            setSelectedImage({
                index: 0,
                url: product.images[0].url
            })
        } else if (index < 0) {
            setSelectedImage({
                index: lastIndex,
                url: product.images[lastIndex].url
            })
        } else {
            setSelectedImage({
                index,
                url: product.images[index].url
            })
        }
    }

    const handleChangeAuction = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setAuction({
            ...auction,
            [name]: value
        })
    }
  
    const handleSubmitAuction = async () => {
        try {
            await saveAuction(auction);
            setmodalIsOpen(false);
            setmodalIsOpen2(true);
           
        } catch (error) {
            alert("Ocorreu um problema no seu lance. Tente novamente.")
            setmodalIsOpen(false);
        }
    }
    
    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const [modalIsOpen2, setmodalIsOpen2] = useState(false);


    const history = useHistory();
    const { state } = queryString.parse(useLocation().search);

    const handleInitAuctionButtonClick = (e: FormEvent) => {
        e.preventDefault();

        if (!buyerProfile) {
            const relativePath = `/products/${formatProductToRouteParams(product)}`; 
            const queryString = `?state=${state}`
            history.push('/login', {
                returnUrl: relativePath + queryString
            })
        }
        setmodalIsOpen(true)
    }

    return (
        loading ? <Style.ProductMainContainer><GifLoading /></Style.ProductMainContainer> :
        <Style.ProductMainContainer>

            <ModalStartAuction modalIsOpen={modalIsOpen} onClose={() => setmodalIsOpen(!modalIsOpen)} onClick={handleSubmitAuction}>
                <Style.ModalContent>
                    <Style.ModalHalf>
                        <Style.ModalRowTitle>Tipo do produto</Style.ModalRowTitle>
                        <Style.ModalTypeRow>
                            <VenatuRadioButton name="state" onChange={handleChangeAuction} label="Novo" value="NEW" />
                            <VenatuRadioButton name="state" onChange={handleChangeAuction} label="Usado" value="USED" />
                        </Style.ModalTypeRow>
                    </Style.ModalHalf>
                    <Style.ModalHalf>
                        <Style.ModalRowTitle>Defina a duração</Style.ModalRowTitle>
                        <Style.ModalDurationRow>
                            <VenatuRadioButton name="duration" value={120} onChange={handleChangeAuction} label="2 horas"  />
                            <VenatuRadioButton name="duration" value={360} onChange={handleChangeAuction} label="6 horas"  />
                            <VenatuRadioButton name="duration" value={600} onChange={handleChangeAuction} label="10 horas"  />
                        </Style.ModalDurationRow>
                        <Style.ModalDurationRow>
                            <VenatuRadioButton name="duration" value={1440} onChange={handleChangeAuction} label="24 horas"  />
                            <VenatuRadioButton name="duration" value={2880} onChange={handleChangeAuction} label="48 horas"  />
                            <VenatuRadioButton name="duration" value={4320} onChange={handleChangeAuction} label="72 horas"  />  
                            </Style.ModalDurationRow>
                    </Style.ModalHalf>
                </Style.ModalContent>   
            </ModalStartAuction>


            <ModalAuctionStarted modalIsOpen={modalIsOpen2} onClose={() => setmodalIsOpen2(!modalIsOpen2)} onClick={() => setmodalIsOpen2(!modalIsOpen2)}>
            <ModalStartedStyle.ModalStartedContent>
                    <ModalStartedStyle.ModalHalf>
                        <ModalStartedStyle.AmountText>Duração definida:</ModalStartedStyle.AmountText>
                        <ModalStartedStyle.ModalStartedRowTitle>2 horas</ModalStartedStyle.ModalStartedRowTitle>
                    </ModalStartedStyle.ModalHalf>
                    <ModalStartedStyle.ModalHalf>
                        <ModalStartedStyle.ModalRow>    
                            <Style.ModalRowTitle>Produto novo</Style.ModalRowTitle>
                            <ModalStartedStyle.AmountText>Agora é só esperar!</ModalStartedStyle.AmountText>
                            <ModalStartedStyle.AmountText>Você será notifcado quando o leilão acabar!</ModalStartedStyle.AmountText>
                        </ModalStartedStyle.ModalRow>
                    </ModalStartedStyle.ModalHalf>
                </ModalStartedStyle.ModalStartedContent>   
            </ModalAuctionStarted>


            <Breadcrumbs object={product} type="PRODUCT" />
            <Style.MainProductContent>

                <Style.ProductImageContainer>
                    <Style.ProductMainPicture>
                        <Style.ArrowButton onClick={() => handleArrowClick(-1)}>
                            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                        </Style.ArrowButton>
                        <Style.SelectedImage
                            src={selectedImage.url} />
                        <Style.ArrowButton onClick={() => handleArrowClick(1)}>
                            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                        </Style.ArrowButton>
                    </Style.ProductMainPicture>

                    <Style.ProductListPicture>
                        {product.images && product.images.map((img, index) => (
                            <Style.Thumbnail
                                key={img.url}
                                src={img.url}
                                onClick={() => setSelectedImage({ index, url: img.url })}
                                active={index === selectedImage.index} />
                        ))}
                    </Style.ProductListPicture>
                </Style.ProductImageContainer>
                <Style.ProductInfoContainer>
                    <Style.ProductTitle>{product.title}</Style.ProductTitle>
                    <Style.DivInline>
                        <ProductState state={product.state} />
                    </Style.DivInline>
                    <Style.PriceInfo>
                        <Style.ProductPrice><CurrencyText value={product.showcasePrice} /></Style.ProductPrice>
                        <Style.PrimaryText>Média do preço de vitrine</Style.PrimaryText>
                    </Style.PriceInfo>
                    <Style.StockInfo></Style.StockInfo>
                    <Style.AmountBox onSubmit={handleInitAuctionButtonClick}>
                        <Style.AmountText>Quantidade</Style.AmountText>
                        <Style.AmountDiv>
                            <Style.AmountInput
                                type="number"
                                value={auction.amount}
                                onChange={handleChangeAuction}
                                name="amount" 
                                required
                            /> 
                            <VenatuButton type="submit" txtColor="#FFF">
                                Iniciar leilão
                            </VenatuButton>

                        </Style.AmountDiv>
                    </Style.AmountBox>
                    <Style.InfoBox>
                        <strong>Formas de pagamento</strong> e <strong>formas de envio</strong> a combinar com o vencedor do leilão.
                    </Style.InfoBox>
                    <Style.InfoBox>
                        <strong>Venatu Garante:</strong> receba o produto ou devolvemos seu dinheiro.
                    </Style.InfoBox>
                </Style.ProductInfoContainer>
            </Style.MainProductContent>
        </Style.ProductMainContainer>
    )
}

export default ProductMainView;