import styled from 'styled-components';

export const Title = styled.p`
    max-width: 100vw;
    display:flex;
    flex-direction: column;
    font-family: var(--font-secondary-medium);
    font-size:1.62rem;
    color: var(--color-strong-gray);
    width: 100%;

    & > small {
        font-family: var(--font-secondary-medium);
        font-size: 1rem;
        color: var(--color-gray);
    }
`
export const Form = styled.form`
    display: flex;
    align-self: center;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: wrap;

    width: 80%;
    background: var(--color-white);
    padding: 2rem;
    margin: 2.5rem 0;

    & > span {
        margin-top: 0.5rem;
        color: var(--color-secondary);
        font-size: 1rem;
    }

    & > h2 {
        width: 100%;
        font-size: 1.5rem;
        margin-top: 2rem;
        color: var(--color-gray);
    }

    & > div {
        width: 49%;
        display: flex;
        justify-content: space-around;
    }

    & > input {
        width: 49%;
        height: 2.85rem;
        padding: 1rem;
        border-radius: 5px;
        color: var(--color-primary);
        border: 1px solid var(--color-gray);
        font-size: 1.2rem;
        ::placeholder {
            color: var(--color-gray);
            font-size: 1.2rem;
        }
    }

    & > .last {
        width: 100%;
        margin-top: 2rem;
        margin-left: 0px;
        display: flex;
        justify-content: flex-start;
        div {
            justify-content: center;
        }
    }

    & > input, select + input {
        margin-top: 1.5rem;
    }
`
