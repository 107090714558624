import api from '../api';

export const initPurchase = async (auctionId: number) => {
    const params = {
        auctionId
    }
    try {
        const { data } = await api.post(`purchase`,params)
        return data;
    } catch (error) {
        console.log("Error on initPurchase")
        throw error;
    }
}

export const findByAuctionId = async (auctionId: number) => {
    try {
        const { data } = await api.get(`purchase-auction/${auctionId}`)
        return data;
    } catch (error) {
        console.log("Error on initPurchase")
        throw error;
    }
}

export const findById = async (id: number) => {
    try {
        const { data } = await api.get(`purchases/${id}`)
        return data;
    } catch (error) {
        console.log("Error on initPurchase")
        throw error;
    }
}

export const getFinishBySeller = async () => {
    try {
        const { data } = await api.get('/purchases-finish');
        return data;
    } catch (error) {
        console.log("===    ERROR ON getFinishBySeller ====")
        console.log(error);
        throw error;
    }
}

export const getFinishedBySeller = async () => {
    try {
        const { data } = await api.get('/purchases-finished');
        return data;
    } catch (error) {
        console.log("===    ERROR ON getFinishedBySeller ====")
        console.log(error);
        throw error;
    }
}