import React from 'react';
import { MainContainer, MainContent, InfoPanel } from '../../assets/GlobalStyles';
import SettingsSideBar from '../../components/SettingsSideBar';
import { useAuth } from '../../contexts/auth';

const Profile = () => {
    const { user } = useAuth();

    return (
        <MainContainer>
            <MainContent>
                <SettingsSideBar />
                <InfoPanel>
                    <p style={{ width: "100%", textAlign: "end" }}>Seja bem-vindo, {user?.username}</p>
                </InfoPanel>
            </MainContent>
        </MainContainer>
    )
}

export default Profile;