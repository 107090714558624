import styled from 'styled-components'

export {
    ProductMainContainer,
    ProductImageContainer,
    ProductMainPicture,
    ProductListPicture,
    ProductInfoContainer,
    ProductTitle,
    ProductPrice,
    PrimaryText,
    StockInfo,
    AmountBox,
    ArrowButton,
    MainProductContent,
    SelectedImage,
    Thumbnail,
    DivInline,
    PriceInfo,
    AmountText,
    AmountDiv,
    AmountInput,
    InfoBox,
    ModalContent,
    ModalTypeRow,
    ModalDurationRow,
    ModalRowTitle,
    ModalHalf,
    ModalAuctionDuration
}

const ProductMainContainer = styled.div`
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 47rem;
    
`

const MainProductContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`

const ProductImageContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;

`
const ProductMainPicture = styled.div`
    width: calc(100% - 4.4rem);
    max-height: 31.25rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3rem;
`

const SelectedImage = styled.img`
    width: calc(100% - 4.4rem);
    height: 31.25rem;
    justify-self: center;

    &:active {
        border: 1px solid var(--color-primary)
    }
`       

const ArrowButton = styled.button`
    background-color: var(--color-primary);
    color: #fff;
    width: 2.2rem;
    height: 5rem;
    border: #FFFFFF;
    cursor: pointer;
    margin: -0.5rem;
    z-index: 2;
    border-radius: 5px;
    box-shadow: #000033 0px 1px 1px;
`

const ProductListPicture = styled.div`
    display: flex;
    flex-flow: row nowrap;
    max-height: 5rem;
    max-width: calc(100% - 4.4rem);
    overflow: hidden;
    margin-top: 1rem;

`

const Thumbnail = styled.img<({active?: boolean})>`
    width: 4.3rem;
    height: 4.3rem;

    border: ${(props: any) => props.active && '2px solid var(--color-primary)'};

    & + & {
        margin-left: 1rem;
    }
`

const ProductInfoContainer = styled.div`
    width: 50%;
    max-width: calc(50%);
    display: flex;
    flex-direction: column;
`
const ProductTitle = styled.p`
    font-size: 1.6rem;
    color: var(--color-strong-gray);
    margin: 0.2rem 0;
`

const DivInline = styled.div`
    display: inline;
`

const PriceInfo = styled.div`
    margin: 2rem 0;
`

const ProductPrice = styled.p`
    font-family: var(--font-secondary);
    font-size: 3.4rem;
`

const PrimaryText = styled.span`
    color: var(--color-primary);
    font-size: 1rem;
`
const StockInfo = styled.div``
const AmountBox = styled.form`
    flex-direction: column;
`

const AmountText = styled.p`
    font-size: 1rem;
    margin: 0.15rem 0;

`

const AmountDiv = styled.div`
    flex-direction: row;
`
const AmountInput = styled.input`
    width: 4.68rem;
    height: 2.8rem;
    padding-left: 0.9rem;
    font-size: 1rem;
    color: var(--color-gray);
`
const InfoBox = styled.p`
    margin-top: 2rem;
    font-size: 0.75rem;
    line-height: 1rem;
    width: 40%;
    color: var(--color-strong-gray);

    strong {
        color: var(--color-secondary);
    }

    & + & {
        margin-top: 1rem;
    }
`
const ModalContent = styled.div`
    display:flex;    
    flex-direction: column;
    width: 100%;
    height: 100%;
`
const ModalTypeRow = styled.div`
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding-top:1.3rem;
`

const ModalDurationRow = styled.div`
    display:flex;
    width: 100%;
    flex-wrap:wrap;
    justify-content: space-around;
    padding-top:1.3rem;
`
const ModalRowTitle = styled.strong`
    display:flex;
    width: 100%;
    justify-content: center;
    padding-top:0.7rem;
    padding-bot:0.1rem;
`
const ModalHalf = styled.div`
    width:100%;
    height:50%;
`
const ModalAuctionDuration =styled.strong`
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: var(--font-secondary);
`