import styled from 'styled-components';

export const NotFoundContainer = styled.div`
    height: 100%;
    background: #F2F2F2;
    opacity: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: max(calc(100vh - 15rem), 37.5rem);
`;

export const Title404 = styled.h1`
    font-size: 8rem;
    color: var(--color-gray);
    margin-bottom: 2rem;
    font-weight: bold;
    
`
export const Description404 = styled.h2`
    font-size: 3rem;
    color: var(--color-strong-gray);
    margin-bottom: 2rem;
`
export const Message404 = styled.h3`
    font-size: 1.6rem;
    color: var(--color-strong-gray);
`