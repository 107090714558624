import React, { useEffect, useState } from 'react';
import { InfoPanel, MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';
import SettingsItemAuction from '../../components/SettingsItemAuction';
import SettingsSideBar from '../../components/SettingsSideBar';
import Auction from '../../interfaces/Auction';
import { getPendingByCustomer } from '../../services/auction';

// import { Container } from './styles';

const CustomerPending: React.FC = () => {
    const [pending, setPending] = useState([] as Auction[]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initPending();
    }, [])

    const initPending = async () => {
        setLoading(true);
        try {
            const data = await getPendingByCustomer();
            setPending(data);
        } catch (error) {
            console.log("Error")
        }
        setLoading(false);
    }

    return (
        <MainContainer>
            <MainContent>
                <SettingsSideBar />
                <InfoPanel>
                    <h1>Compras</h1>
                    <h2>Pendentes</h2>

                    <h3>Leilões pendentes</h3>
                    {
                        loading ? <GifLoading /> :
                        pending.length > 0 ?
                            pending.map(auction => <SettingsItemAuction key={auction.id} auction={auction} />)
                        :
                            <p>Nenhum leilão pendente.</p>
                    }
                </InfoPanel>
            </MainContent>
        </MainContainer>   
  )
}

export default CustomerPending;