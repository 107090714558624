import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { MainContainer } from '../../assets/GlobalStyles';
import { RegisterInput, RegisterSelect } from '../Auth/Register/style';
import InputMask from 'react-input-mask';

import { Title, Form } from './styles'
import isValidCNPJ from '../../util/isValidCnpj';
import GifLoading from '../../components/GifLoading';
import VenatuButton from '../../components/VenatuButton';

import * as service from '../../services/bank-account'
import { useAuth } from '../../contexts/auth';
import { useHistory } from 'react-router-dom';
import { isValidCPF } from '../../util/isValidCpf';


const CompleteRegister = () => {
    const { loadMe, buyerProfile } = useAuth();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [formData, setFormData] = useState({
        legal_name: "",
        document_number: '',
        bank_code: '',
        agency: '',
        agency_dv: '',
        account: '',
        account_dv: '',
        type: 'conta_corrente'
    })

    const [documentMask, setDocumentMask] = useState("99999999999999")

    const banksList = [
        { code: '001', value: 'Banco do Brasil S.A'},
        { code: '237', value: 'Banco Bradesco S.A'},
        { code: '104', value: 'Caixa Econômica Federal'},
        { code: '745', value: 'Banco Citibank S.A'},
        { code: '399', value: 'HSBC Bank Brasil S.A - Banco Múltiplo'},
        { code: '341', value: 'Banco Itaú S.A'},
        { code: '652', value: 'Itaú Unibanco Holding S.A'},
        { code: '422', value: 'Banco Safra S.A'},
        { code: '033', value: 'Banco Santander (Brasil) S.A'},
        { code: '260', value: 'Nu Pagamentos S.A'},
    ]

    useEffect(() => {
        if (buyerProfile) {
            setFormData(fm => ({
                ...fm,
                document_number: buyerProfile.cpf,
                legal_name: buyerProfile.first_name,
            }))

            setDocumentMask('999.999.999-99')
        }
    }, [buyerProfile])

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!isValidCNPJ(formData.document_number) && !isValidCPF(formData.document_number)) {
            alert("Documento inválido");
            return;
        }
        
        setLoading(true);
        try {
            await service.save(formData);
            await loadMe();
            history.push('/vender')
        } catch (error) {
            alert('Verifique seus dados e tente novamente')
        }
        setLoading(false);
    }

    const handleBlurDocument = (document_number: string) => {
        document_number = document_number.replace(/[^\d]+/g,'');
        if (isValidCPF(document_number)) {
            setDocumentMask("999.999.999-99")
        } else if(isValidCNPJ(document_number)) {
            setDocumentMask("99.999.999/9999-99")
        } else {
            setFormData(f => ({ ...f, document_number: '' }))
            setDocumentMask("99999999999999")
        }
    }

    const handleFormData = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value, name } = e.target

        setFormData(fm => ({...fm, [name]: value}))

        if (name === 'document_number') {
            const doc = value.replace(/[^\d]+/g,'');
            if (doc === '' || doc.length === 1) {
                setDocumentMask("99999999999999")
            }
        }
    }


    return (
        <MainContainer>
            <Form onSubmit={onSubmit}>
                <Title>
                    Confirme seu cadastro
                    <small>Preencha as informações comerciais e bancárias</small>
                </Title>

                <h2>Dados do vendedor</h2>
                <RegisterInput
                    name="legal_name"
                    placeholder="Nome social ou nome da empresa"
                    value={formData.legal_name}
                    onChange={handleFormData}
                    maxLength={30}
                    required
                />

                <InputMask 
                    mask={documentMask}
                    name="document_number"
                    placeholder="CPF ou CNPJ"
                    value={formData.document_number}
                    onChange={handleFormData}
                    onBlur={({ target }) => handleBlurDocument(target.value)}
                    required
                />

                <h2>Dados bancários</h2>

                <RegisterSelect 
                    name="bank_code"
                    value={formData.bank_code} 
                    onChange={handleFormData} 
                    defaultValue="" 
                    required
                >
                    <option disabled value="">Banco</option>
                    {banksList.map(bank => (
                        <option value={bank.code}>{bank.code} - {bank.value}</option>    
                    ))}
                </RegisterSelect>

                <RegisterSelect 
                    name="type"
                    value={formData.type} 
                    onChange={handleFormData} 
                    defaultValue="" 
                    required
                >
                    <option disabled value="">Tipo de conta</option>
                    <option value="conta_corrente">Conta corrente</option>
                    <option value="conta_corrente_conjunta">Conta corrente conjunta</option>
                    <option value="conta_poupanca">Conta poupança</option>
                    <option value="conta_poupanca_conjunta">Conta poupança conjunta</option>
                </RegisterSelect>

                <div>
                    <RegisterInput
                        name="agency"
                        placeholder="Agência"
                        value={formData.agency}
                        onChange={handleFormData}
                        width="80%"
                        required
                        minLength={3}
                        maxLength={5}
                    />

                    <RegisterInput
                        name="agency_dv"
                        placeholder="DV"
                        value={formData.agency_dv}
                        onChange={handleFormData}
                        width="15%"
                        maxLength={1}
                    />
                </div>
                
                <div>
                    <RegisterInput
                        name="account"
                        placeholder="Conta"
                        value={formData.account}
                        onChange={handleFormData}
                        required
                        maxLength={13}
                    />

                    <RegisterInput
                        name="account_dv"
                        placeholder="DV"
                        value={formData.account_dv}
                        onChange={handleFormData}
                        required
                        maxLength={2}
                    />
                </div>
                
                <div className="last">
                    {loading ? <GifLoading /> : (
                        <VenatuButton 
                            fs="1.5rem"
                            width="20%"
                            type="submit"
                            txtColor={"var(--color-white)"}
                        >
                            Finalizar cadastro
                        </VenatuButton>
                    )}
                </div>
            </Form>
        </MainContainer>
    )
}

export default CompleteRegister