import React from 'react';
import { BrowserRouter, Route, Switch  } from 'react-router-dom'

import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import PageHeader from './components/PageHeader';
import { useAuth } from './contexts/auth';
import Search from './pages/Search';
import ProductInserted from './pages/ProductInserted';

import PageFooter from './components/PageFooter';
import Product from './pages/Product';
import TermosECondicoes from './pages/TermosECondicoes';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade';
import ProductRegistration from './pages/ProductRegistration';
import Profile from './pages/Profile';
import CustomerAuctions from './pages/CustomerAuctions';
import SellerAuctions from './pages/SellerAuctions';
import SellerProducts from './pages/SellerProducts';
import AuctionDetail from './pages/AuctionDetail';
import CustomerAbandoned from './pages/CustomerAbandoned';
import CustomerFinished from './pages/CustomerFinished';
import CustomerPending from './pages/CustomerPending';
import PurchaseAuction from './pages/PurchaseAuction';
import CompleteRegister from './pages/CompleteRegister';
import SellerFinish from './pages/SellerFinish';
import SellerFinished from './pages/SellerFinished';
import PurchaseDetail from './pages/PurchaseDetail';

const Routes = () => {
    const { signed } = useAuth();

    return (
        <BrowserRouter>
            <PageHeader />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/termos-e-condicoes" exact component={TermosECondicoes} />
                <Route path="/politicas-de-privacidade" exact component={PoliticaPrivacidade} />
                <Route path="/comprar" exact component={Search} />
                <Route path="/products/:id-:slag" component={Product} />
                
                { signed && <Route path="/perfil" exact component={Profile} />}
                { signed && <Route path="/perfil/comprar/leiloes" exact component={CustomerAuctions} />}
                { signed && <Route path="/perfil/comprar/pendentes" exact component={CustomerPending} />}
                { signed && <Route path="/perfil/comprar/finalizados" exact component={CustomerFinished} />}
                { signed && <Route path="/perfil/comprar/abandonados" exact component={CustomerAbandoned} />}
                { signed && <Route path="/perfil/vender/lances" exact component={SellerAuctions} />}
                { signed && <Route path="/perfil/vender/arremates" exact component={SellerFinish} />}
                { signed && <Route path="/perfil/vender/finalizados" exact component={SellerFinished} />}
                { signed && <Route path="/perfil/vender/produtos" exact component={SellerProducts} />}
                { signed && <Route path="/perfil/vendas/:id" exact component={PurchaseDetail} />}
                { signed && <Route path="/perfil/leilao/:id" exact component={AuctionDetail} />}
                { signed && <Route path="/perfil/leilao/:id/finalizar" exact component={PurchaseAuction} />}
                { signed && <Route path="/completar-cadastro" exact component={CompleteRegister} />}
                { signed && <Route path="/vender" exact component={ProductRegistration} />}
                { signed && <Route path="/vender/sucesso" exact component={ProductInserted} />}
                { !signed && <Route path="/login" exact component={Login} />}
                { !signed && <Route path="/register" exact component={Register} />}

                <Route exact component={NotFound} />
            </Switch>
            <PageFooter />
        </BrowserRouter>
    )
}

export default Routes;