export const formatDate = (date: any) => {
    if (!date) return "";
    const dt = new Date(date);

    const formatedDate = new Intl.DateTimeFormat("pt-BR", {
                            year: 'numeric',
                            month: "2-digit",
                            day: '2-digit',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric'
                        }).format(dt);

    return formatedDate;
}

export const formatOnlyDate = (date: any) => {
    if (!date) return "";
    const dt = new Date(date);

    const formatedDate = new Intl.DateTimeFormat("pt-BR", {
                            year: 'numeric',
                            month: "2-digit",
                            day: '2-digit',
                        }).format(dt);

    return formatedDate;
}