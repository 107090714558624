import React, { useState, useEffect } from 'react';

import { FilterPanel, DataPanel, FilterBreadcrumb } from './style';
import ItemProduct from '../../components/ItemProduct';
import api from '../../services/api';
import queryString from 'query-string';
import {  useLocation } from 'react-router-dom';
import ListPagination from '../../components/ListPagination';
import { MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';

const Search = () => {
    const { categoryId, query } = queryString.parse(useLocation().search);
    const [products, setProducts] = useState([] as any);
    const [filteredCategory, setFilteredCategory] = useState({} as any)
    const [filter, setFilter] = useState({
        categoryId: categoryId,
        search: query,
        limit: 5,
        page: 1
    })
    
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0);

    useEffect(() => {
        initProducts();
    }, [filter.page, filter.search])

    useEffect(() => {
        setFilter(f => ({...f, page: 1}))
    }, [filter.search])

    useEffect(() => {
        setFilter(f => ({
            ...f,
            categoryId: filteredCategory.id || undefined,
            search: query
        }))

    }, [query, filteredCategory])

    useEffect(() => {
        initProducts();
    }, [])

    useEffect(() => {
        (async function loadCategory() {
            if (categoryId) {
                const { data } = await api.get(`categories/${categoryId}`);
                setFilteredCategory(data);
            }
        })()
    }, [categoryId])


    async function initProducts() {
        const params = {
            search: filter.search,
            limit: filter.limit,
            page: filter.page,
            categoryId: filter.categoryId || undefined
        }
        setLoading(true)
        try {
            const response = await api.get('products', { params });
            const { data } = response;
            const hCount = response.headers['x-total-count'];
            setProducts(data);
            setCount(hCount)
        } catch (error) {
            console.log("Error")
        }
        
        setLoading(false);
    }

    async function onPaginationClick(page: number) {
        setFilter({
            ...filter,
            page
        })
    }

    return (
        <MainContainer>
            <MainContent>
                <FilterPanel>
                    <FilterBreadcrumb>
                        {filteredCategory.title || 'Nenhuma categoria selecionada'}
                    </FilterBreadcrumb>

                    <p>{query || 'Exibindo todos os produtos'}</p>
                    <p>{count || 0} resultados</p>
                </FilterPanel>
                <DataPanel>
                    {
                        loading ? <GifLoading /> :
                        products.length > 0 ?
                        <>
                            {products.map((product: any) => 
                                <ItemProduct product={product} key={product.id} />
                            )}
                        
                            <ListPagination
                                initialPage={filter.page}
                                pageClick={onPaginationClick}
                                totalRecords={count}
                            />
                        </>
                        :
                        <p>Nenhum resultado</p>
                    }
                    
                </DataPanel>
            </MainContent>
        </MainContainer>
    )
}   

export default Search;