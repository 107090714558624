import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const TitleRow = styled.div<{ isMyOffer?: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.3rem 0;

    & > p + p {
        font-weight: ${props => props.isMyOffer ? "bold" : "regular"};
        color: ${props => props.isMyOffer ? 'var(--color-primary)' : "inherit"};
    }
`

export const HourLabel = styled.p`
    font-size: 1rem;
    color: var(--color-gray);
    width: 40%;
`
export const CurrentPriceLabel = styled.p`
    font-size: 1rem;
    color: var(--color-gray);
    width: 30%;

    & > small {
        margin-left: 0.2rem;
        background-color: var(--color-secondary);
        color: var(--color-white);
        font-size: 0.75rem;
        padding: 0.3rem;
    }

`
export const LastBidLabel = styled.p`
    font-size: 1rem;
    color: var(--color-gray);
    width: 30%;
    text-align: right;
`