import React from 'react';
import { useHistory } from 'react-router-dom';
import Auction from '../../../interfaces/Auction';
import { Row } from '../../../pages/Auth/Login/style';
import getAuctionsAvailableUntil from '../../../util/getAuctionAvailableUntil';
import VenatuButton from '../../VenatuButton';
import VenatuCountDown from '../../VenatuCountDown';

import { Container } from './styles';

interface NotParticipateBoxProps {
  auction: Auction,
  onParticipate: () => void
}
const NotParticipateBox: React.FC<NotParticipateBoxProps> = ({ auction, onParticipate }) => {
  const availableUntil = getAuctionsAvailableUntil(auction);

  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  }

  const handleOnParticipate = async () => {
    await onParticipate();
    handleGoBack();
  }

  return (
    <Container>
      {
      auction.available ? 
        <>
          <p>Leilão em andamento</p>
          <strong>Resta <VenatuCountDown date={availableUntil}>finish</VenatuCountDown></strong>  
      
          <Row>
            <VenatuButton onClick={handleGoBack} bgColor="var(--color-white)" txtColor="var(--color-secondary)">Voltar</VenatuButton>
            <VenatuButton onClick={handleOnParticipate} txtColor="var(--color-white)">Participar</VenatuButton>
          </Row>
        </>
      :
        <>
          <p>Leilão encerrado</p>
          <VenatuButton 
            onClick={handleGoBack}
            bgColor="var(--color-white)" 
            txtColor="var(--color-secondary)"
          >
            Voltar
          </VenatuButton>
        </>
      }
    </Container>
  )
}

export default NotParticipateBox;