import styled from 'styled-components';

export const Container = styled.div`
    display: inline;
    font-size: 1rem;
    font-family: var(--font-primary);
    background-color: var(--color-gray);
    color: #fff;
    padding: 0.1rem 0.6rem;
`;
