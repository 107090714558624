import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';

 import * as Style from './style';

import { MainContainer, MainForm } from '../../assets/GlobalStyles';
import ProductSpecStandart from '../../components/ProductSpecStandart';
import CustomDropzone from '../../components/CustomDropzone';
import VenatuButton from '../../components/VenatuButton';
import VenatuDropdown from '../../components/VenatuDropdown';
import IProduct from '../../interfaces/Product';
import Category from '../../interfaces/Category';
import { getCategories } from '../../services/category';
import ProductSpecDropdown from '../../components/ProductSpecDropdown';
import IFileUploaded from '../../interfaces/IFileUploaded';
import { buildFormData } from '../../util/util';
import { saveProduct } from '../../services/product';
import GifLoading from '../../components/GifLoading';
import { useHistory } from 'react-router-dom';
import VenatuRadioButton from '../../components/VenatuRadioButton';
import VenatuCurrency from '../../components/VenatuCurrency';
import { useAuth } from '../../contexts/auth';

const ProductRegistration : React.FC = () => {
    const { sellerProfile } = useAuth();
    const [product, setProduct] = useState<IProduct>({ } as IProduct);

    const [selectedCategory, setSelectedCategory] = useState<Category>({} as Category);
    const [categories, setCategories] = useState<Category[]>([] as Category[]);
    const [images, setImages] = useState<IFileUploaded[]>([{}] as IFileUploaded[]);

    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (sellerProfile) {
            const { recipient_id } = sellerProfile;
            if (!recipient_id) history.push('/completar-cadastro')
        }
    }, [sellerProfile])

    function handleChange (e : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        const { name, value } = e.target;
        setProduct(p => ({ ...p, [name]: value}));
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        if (loading) return;

        const formData = {
            ...product,
            
        }

        const data = new FormData();
        buildFormData(data, formData);
        const productImages = images.filter(i => i.file).map(i => i.file);

        if (productImages.length <= 0) {
            alert('Selecione pelo menos 1 imagem');
            return;
        }

        for(let i = 0; i < productImages.length; i++)
            data.append('images', productImages[i]);

        setLoading(true);
        try {
            await saveProduct(data);
            history.push('/vender/sucesso');
        } catch (error) {
            console.log('====   SAVE PRODUCT ERROR  ====')
            console.log(error);
            alert('Erro ao cadastrar produto: ' + error);
        }
        setLoading(false);
    }

    function handleInitCategory() {
        setProduct(p => ({ ...p,  categoryId : Number(undefined) }))
    }

    function handleCategorySelected(e: ChangeEvent<HTMLSelectElement>) {
        const { name, value } = e.target;
        setProduct(p => ({ ...p, [name]: value}));
        const c = categories.filter(cat => cat.id === Number(value))[0];
        setSelectedCategory(c);
    }

    useEffect(() => {
        (async function () {
            const data = await getCategories();
            setCategories(data);
        })()
    }, [])

    useEffect(() => {
        console.log(product);
    }, [product])

    function onFileUpload(file: IFileUploaded, index = 0) {
        const empty = {} as IFileUploaded;
        if (images[index]) {
            const serialized = images.map((img, i) => i === index ? file : img);
            setImages([...serialized, empty]);
            return;
        }
        setImages(f => ([...f, file, empty]));
    }

    const handleCurrency = (name: string, value: number) => {
        setProduct(p => ({
            ...p,
            [name]: value
        }))
    }

    return (
        <MainContainer>
            <MainForm onSubmit={handleSubmit}>
                {
                    !product.categoryId ? 
                        <Style.CategoryRegistrationContent>
                            <Style.Title>Qual tipo de produto você quer vender?</Style.Title>
                            <div>
                                <VenatuDropdown name="categoryId" onChange={handleCategorySelected} defaultValue={product.categoryId}>
                                    {categories && categories.map(cat => (
                                        <option key={cat.id} value={cat.id}>{cat.title}</option>
                                    ))}
                                </VenatuDropdown>
                            </div>
                        </Style.CategoryRegistrationContent>
                    :
                <Style.ProductRegistrationContent>
                    <Style.Title>Especifique o seu produto</Style.Title>
                    <small>Você está vendendo {selectedCategory.title}</small>

                    <ProductSpecStandart id="title" name="title" required onChange={handleChange} label="Título do produto*" placeholder="Título do produto" value={product.title}   />
                    <Style.Row>
                        <Style.Column>
                            <Style.ProductTitle>Estado do Produto</Style.ProductTitle>
                            <Style.Radio>
                                <VenatuRadioButton onChange={handleChange} name="state" id="state_novo" label="Novo" value="NEW" />
                                <VenatuRadioButton onChange={handleChange} name="state" id="state_usado" label="Usado" value="USED" />
                            </Style.Radio>
                        </Style.Column>

                        <Style.Column>
                            <ProductSpecStandart id="amount" name="amount" onChange={handleChange} label="Quantidade disponível*" required type="number" placeholder="0" value={String(product.amount)}   />
                        </Style.Column>

                        <Style.Column>
                            {/* <ProductSpecStandart id="minimumPrice" name="minimumPrice" onChange={handleChange} label="Preço mínimo*" required type="number" placeholder="R$ 2.500,00" value={String(product.minimumPrice)}   /> */}

                            <VenatuCurrency 
                                label="Preço mínimo:*" 
                                id="minimumPrice" 
                                name="minimumPrice" 
                                placeholder="R$ 2.500,00"
                                onChangeGetFloatValue={(value: number) => handleCurrency('minimumPrice', value)} 
                                value={String(product.minimumPrice)}
                            />

                        </Style.Column>

                        <Style.Column>
                            <VenatuCurrency 
                                label="Preço de vitríne:*" 
                                id="showcasePrice" 
                                name="showcasePrice" 
                                placeholder="R$ 4.500,00"
                                onChangeGetFloatValue={(value: number) => handleCurrency('showcasePrice', value)} 
                                value={String(product.showcasePrice)}
                            />
                        {/* <ProductSpecStandart id="showcasePrice" name="showcasePrice" onChange={handleChange}  label="Preço de vitríne*" required type="number" placeholder="R$ 4.500,00" value={String(product.showcasePrice)}   /> */}
                        </Style.Column>
                    </Style.Row>
                    
                    <Style.RowStart>
                        {images && images.map((img, i) => 
                            <CustomDropzone key={i} onFileUploaded={onFileUpload} index={i} main={i === 0} />
                        )}
                    </Style.RowStart>

                    <Style.ProductSpecContainer>
                        <ProductSpecStandart id="brand" name="brand" onChange={handleChange} label="Marca*" required placeholder="Marca do produto" value={product.brand}   />
                        <ProductSpecStandart name="model" id="model" onChange={handleChange} label="Modelo*" required placeholder="Modelo do produto" value={product.model}   />
                        <ProductSpecStandart name="manufacturer" id="manufacturer" onChange={handleChange} label="Fabricante:*" placeholder="Ex: Motorola, LG, Samsung." value={product.manufacturer}   />
                        <ProductSpecStandart name="universalCode" id="universalCode" onChange={handleChange} label="Código universal do produto" placeholder="EAN, UPC ou outro GTIN" value={product.universalCode}   />
                        
                        <ProductSpecDropdown 
                            name="voltage" 
                            id="voltage" 
                            onChange={handleChange} 
                            label="Voltagem" 
                            value={product.voltage}
                        >
                            <option value="110v">110v</option>
                            <option value="220v">220v</option>
                            <option value="Bivolt">Bivolt</option>
                        </ProductSpecDropdown>

                        <ProductSpecStandart name="color" id="color" onChange={handleChange} label="Cor:*" placeholder="Preto, Branco..." value={product.color}   />

                        <Style.Description>
                            <Style.TitleDescription>Descrição</Style.TitleDescription>
                            <Style.TextAreaDescription name="description" onChange={handleChange} value={product.description} placeholder="Quais as características do produto? Sugerimos listar em parágrafos."/>
                        </Style.Description>
                    </Style.ProductSpecContainer>

                    <Style.ContinueBackContainer>
                        {
                            loading ? <GifLoading /> : <>
                                <VenatuButton type="reset" bgColor="#fff" onClick={handleInitCategory}>Voltar</VenatuButton>
                                <VenatuButton txtColor="#fff">Continuar</VenatuButton>
                            </>
                        }
                    </Style.ContinueBackContainer>   
                </Style.ProductRegistrationContent>
}
                </MainForm>
            </MainContainer>
        )
}

export default ProductRegistration;