import React from 'react';
import { useHistory } from 'react-router-dom';
import Auction from '../../interfaces/Auction';
import { Column } from '../../pages/ProductRegistration/style';
import VenatuButton from '../VenatuButton';

import { AuctionDetails, ProductInfo, AuctionInfo, Container } from './styles';

interface SettingsItemAuctionProps {
    auction: Auction
}
const SettingsItemAuction: React.FC<SettingsItemAuctionProps> = ({ 
    auction 
}) => {
    const history = useHistory();

    function handleClickAccessAuction() {
        history.push(`/perfil/leilao/${auction.id}`)
    }

    return (
        <Container>
            <ProductInfo>
                <img src={auction.product.imageUrl} alt={auction.product.title} />
                <Column>
                    <strong>{auction.product.title}</strong>
                </Column>
            </ProductInfo>
            <AuctionDetails>
                <AuctionInfo>
                    <VenatuButton 
                        onClick={handleClickAccessAuction}
                        txtColor="#fff"
                    >Acessar leilão</VenatuButton>
                </AuctionInfo>
            </AuctionDetails>
        </Container>
  );
}

export default SettingsItemAuction;
