import React from 'react';
import * as Style from './styles';

import { useHistory } from 'react-router-dom';
import  VenatuButton from '../../components/VenatuButton';
import { MainContainer } from '../../assets/GlobalStyles';

const ProductInserted : React.FC = () => {
    const history = useHistory();
    function handleUrl(url: string) {
        history.push(url);
     }
    
    return (
        <MainContainer>
            <Style.ProductInsertContainer>
                <Style.ProductInsertDiv>
                    <Style.Title>Produto cadastrado!</Style.Title>
                    <Style.ButtonsDiv>
                        <VenatuButton bgColor="#fff" onClick={() => handleUrl('/')}>Ir para Conta</VenatuButton>  
                        <VenatuButton txtColor="#fff" onClick={() => handleUrl('/vender')}>Cadastrar outro</VenatuButton>
                    </Style.ButtonsDiv>
                </Style.ProductInsertDiv>
            </Style.ProductInsertContainer>
        </MainContainer>

    )}

export default ProductInserted;