import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-white);
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  max-height: 6.25rem;
  & > div {
    box-shadow: transparent 0px 0px 0px !important;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  align-items: center;

  & > img {
    background-color: #FFFFFF;
    box-shadow: #00000033 0px 1px 1px;
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
  }

  & > div > strong {
    font-size: 1rem;
    color: var(--color-secondary);
  }
`

export const AuctionDetails = styled.div`
  text-align: end;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  padding: 1rem 0;
  width: 30%;
`
export const AuctionInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
`

export const AuctionDetailsRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  
  & + div {
    padding-top: 0.5rem;
  }

`
