import React from 'react';

import { Rotate, Loading } from './style'

const GifLoading : React.FC = () => {
    return (
        <Rotate>
            <Loading />
        </Rotate>
    )
}

export default GifLoading