import React from 'react';
import "./style.tsx";

import { Text , Title, DivContainer } from './style';
import { MainContainer } from '../../assets/GlobalStyles';


const PoliticaPrivacidade = () => {
    return (
        <MainContainer>
            <DivContainer>
            <Title>POLÍTICA DE PRIVACIDADE</Title>
            <Title>INTRODUÇÃO</Title>
            <Text>A VENATU se compromete com a sua privacidade. A seguir detalhamos a maneira de coleta, 
                utilização, divulgação, retenção e proteção de seus dados pessoais.
                Por meio do Cadastro de Usuário preenchido coletamos seus dados pessoais quando efetuar o
                 cadastro na plataforma www.venatu.com.br, (com a validação de e-mail e número de celular) e
                  assim tido por habilitado após autenticação para efetuar compras e realizar vendas na plataforma.
                   Isso facilita a oferecer serviços e produtos que melhorem sua experiência de compra e venda, como
                    a segurança da plataforma. É possível acessar, conferir e mudar seus dados pessoais a qualquer momento.
            </Text>
            <Title>SEÇÃO 1 - O QUE FAREMOS COM ESTA INFORMAÇÃO?</Title>
            <Text>Quando você realiza alguma transação na nossa plataforma, 
                como parte do processo de compra e venda, coletamos as informações
                 pessoais que você nos dá tais via preenchimento de cadastro com validação
                  por e-mail e telefone pessoal, estando assim logado e habilitado para efetuar 
                  as transações de compra e venda.
                    Quando você acessa nosso site, também recebemos automaticamente o protocolo de 
                    internet do seu computador, endereço de IP, a fim de obter informações que nos ajudam 
                    a aprender sobre seu navegador e sistema operacional.
            </Text>
            <Title>SEÇÃO 2 - CONSENTIMENTO</Title>
            <Text>
            Como vocês obtêm meu consentimento?
Quando você fornece informações pessoais, tem o objetivo facilitar a oferta de servilos e produtos e assim, completar transação, verificar seu cartão de crédito/débito ou outra forma de pagamento  direta como boleto, fazer um pedido, providenciar uma entrega ou retornar uma compra. Após a realização de ações entendemos que você está de acordo com a coleta de dados para serem utilizados pela nossa empresa.
Se pedimos por suas informações pessoais por uma razão secundária, como marketing, vamos lhe pedir diretamente por seu consentimento, ou lhe fornecer a oportunidade de dizer não.
            </Text>
            <Title>E caso você queira retirar seu consentimento, como proceder?</Title>
            <Text>Se após você nos fornecer seus dados, você mudar de ideia, você pode 
                retirar o seu consentimento para que possamos entrar em contato, para a c
                oleção de dados contínua, uso ou divulgação de suas informações, a qualquer momento, entrando em contato conosco em contato @venatu.com.brou nos enviando uma correspondência em: VENATU, Rua Afonso Pena, 384, sala 1, Canto, Florianópolis/sc, Cep 88070-650.               
            </Text>
            <Title>SEÇÃO 3 – DIVULGAÇÃO</Title>
            <Text>
            Podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de Serviço.
            </Text>
            <Title>SEÇÃO 4 - SERVIÇOS DE TERCEIROS</Title>
            <Text>
            No geral, os fornecedores terceirizados usados por nós irão apenas coletar, usar e divulgar suas informações na medida do necessário para permitir que eles realizem os serviços que eles nos fornecem.
Entretanto, certos fornecedores de serviços terceirizados, tais como gateways de pagamento e outros processadores de transação de pagamento, têm suas próprias políticas de privacidade com respeito à informação que somos obrigados a fornecer para eles de suas transações relacionadas com compras.
Para esses fornecedores, recomendamos que você leia suas políticas de privacidade para que você possa entender a maneira na qual suas informações pessoais serão usadas por esses fornecedores.
Em particular, lembre-se que certos fornecedores podem ser localizados em ou possuir instalações que são localizadas em jurisdições diferentes que você ou nós. Assim, se você quer continuar com uma transação que envolve os serviços de um fornecedor de serviço terceirizado, então suas informações podem tornar-se sujeitas às leis da(s) jurisdição(ões) nas quais o fornecedor de serviço ou suas instalações estão localizados.
Uma vez que você deixe a plataforma www.venatu.com.br  ou  seja redirecionado para um aplicativo ou site de terceiros, você não será mais regido por essa Política de Privacidade ou pelos Termos de Serviço do nosso site.
            </Text>
            <Title>Links</Title>
            <Text>
            Quando você clica em links na nossa loja, eles podem lhe direcionar para fora do nosso site. Não somos responsáveis pelas práticas de privacidade de outros sites e lhe incentivamos a ler as declarações de privacidade deles.
            </Text>
            <Title>SEÇÃO 5 - SEGURANÇA</Title>
            <Text>Para proteger suas informações pessoais, tomamos precauções razoáveis e seguimos as melhores práticas da indústria para nos certificar que elas não serão perdidas inadequadamente, usurpadas, acessadas, divulgadas, alteradas ou destruídas.
                    Se você nos fornecer as suas informações de cartão de crédito, essa informação é criptografada usando tecnologia "secure socket layer" (SSL) e armazenada com uma criptografia AES-256. Embora nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro, nós seguimos todos os requisitos da PCI-DSS e implementamos padrões adicionais geralmente aceitos pela indústria.
            </Text>
            <Title>SEÇÃO 6 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</Title>
            <Text>Reservamos o direito de modificar essa política de privacidade a qualquer momento, então por favor, revise-a com frequência. Alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na plataforma www.venatu.com.br. Se fizermos alterações de materiais para essa política, iremos notificá-lo aqui que eles foram atualizados, para que você tenha ciência sobre quais informações coletamos, como as usamos, e sob que circunstâncias, se alguma, usamos e/ou divulgamos.
                    Se nossa loja for adquirida ou fundida com outra empresa, suas informações podem ser transferidas para os novos proprietários para que possamos continuar a vender produtos para você.
            </Text>
            <Title>POLÍTICA DE RESPONSABILIDADE </Title>
            <Text>
                I. Salvo nos casos de falta de pagamento, divulgação não autorizada de informações confidenciais e/ou violação de Direitos de Propriedade Intelectual, a responsabilidade total de qualquer das Partes perante a outra Parte, resultante a qualquer tempo destes
                Termos de Venda, não excederá o valor faturado pela Venatu e pago pelo Cliente durante os 12 (doze) meses imediatamente anteriores nos termos do Documento de Compra aplicável que originou o dano. Com exceção dos casos de divulgação não autorizada de informações confidenciais e/ou violação de Direitos de Propriedade Intelectual, as Partes serão responsáveis somente por danos emergentes, excluindo, sem limitação, danos punitivos,
                especiais, pessoais, incidentais, morais, estéticos, indiretos, lucros cessantes, perda de chances, perdas de receitas, danos à imagem e/ou da reputação, perda ou corrupção de Software, sistemas, rede, da base de dados e de informações e, ainda, eventual recuperação dos mesmos, interrupção ou suspensão dos negócios e danos imprevistos ou despesas para a obtenção de soluções substitutas.
                II. A Venatu expressamente declara que não se responsabiliza civilmente, em nenhuma hipótese, por Produtos de Terceiros e software de código aberto ou software livre, ou em caso de quaisquer falhas nos Produtos, Software e/ou Serviços se utlizados em Atividades de Alto Risco.

                III. A Venatu também não será responsável perante o Cliente por pleitos formulados ou direitos alegados por terceiros contra o Cliente, salvo em caso de (i) fraude, conduta criminosa, dolo ou culpa grave comprovada da Dell de que resultem danos à propriedade tangível, danos corporais ou morte ou (ii) violação dos Direitos de Propriedade Intelectual de terceiros relativos aos Produtos, Software, e/ou Serviços. Nas hipóteses acima, a Venatu somente ressarcirá o Cliente por pleitos formulados ou direitos alegados por terceiros conforme limites e parâmetros estabelecidos na cláusula acima.
                IV. As exclusões e limitações de responsabilidade previstas nestes Termos de Venda configuram elementos essenciais deste e foram levadas em conta na formação dos preços apresentados ao Cliente.
                V. A Venatu reconhece que não existe vínculo empregatício entre os trabalhadores designados para a prestação dos Serviços contratado e o Cliente.
                VI. Como condição para a aplicação do disposto na cláusula acima, o Cliente, quando intimado/notificado/cientificado da ação judicial e/ou processo administrativo decorrente dos presentes Termos de Venda, deverá: 
                (I) Notificar a Venatu, por escrito, em prazo que possibilite a defesa da Venatu ou para que a Venatu auxilie na defesa do Cliente, definido pelo prazo equivalente à metade do prazo legal ou determinado pelo órgão competente para apresentar informações e/ou defesa, exceto quando o Cliente comprovar que foi intimado em menor tempo e proceder à notificação à Venatu no mesmo dia; 
                (II) Prestar as informações necessárias para que a Venatu prepare e apresente sua defesa.
                VII. O não atendimento do disposto na cláusula acima exime a Venatu de qualquer responsabilidade imposta ao Cliente disposta na cláusula V acima.
                VIII. O Cliente é responsável pela realização de cópia de segurança (backup) das suas informações. As conseqüências de sua perda (como perdas e danos e lucros cessantes) sob nenhuma hipótese poderão ser imputadas à Venatu.
            </Text>
            </DivContainer>
        </MainContainer>
    )
}

export default PoliticaPrivacidade;