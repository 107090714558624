import React, { SelectHTMLAttributes } from 'react';
import { Dropdown } from './style';

interface VenatuDropdownProps extends SelectHTMLAttributes<HTMLSelectElement> {
}
const VenatuDropdown : React.FC<VenatuDropdownProps> = ({ 
    children, 
    ...props
} : VenatuDropdownProps) => {
    return (
        <Dropdown
            {...props} 
        >
            <option value="">Selecione um item</option>
            {children}
        </Dropdown>
    )
}

export default VenatuDropdown