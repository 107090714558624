import styled from "styled-components";

export {
    ItemProductContainer,
    ItemProductImage,
    ItemProductInfo,
    ItemProductTitle,
    ItemProductShowcaseMediaPrice,
    ItemProductPrice,        
}

const ItemProductContainer = styled.div`
    background-color: #FFFFFF;
    box-shadow: #00000033 0px 1px 1px;
    display: flex;
    margin: 0 0 1rem 0;
    padding: 1rem;
    cursor: pointer;
`

const ItemProductImage = styled.img`
    background-color: #FFFFFF;
    box-shadow: #00000033 0px 1px 1px;
    width: 10rem;
    height: 10rem;
    margin-right: 1rem;
`

const ItemProductInfo = styled.div`
    background-color: #FFFFFF;
    width: 100%;
    height: 10rem;
    padding: 1rem;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    
`

const ItemProductTitle = styled.p`
    color: #616162;
    margin: 0 0 1rem 0;
`

const ItemProductShowcaseMediaPrice = styled.p`
    color: var(--color-primary);
    width: 100%;
`

const ItemProductPrice = styled.p`
    background-color: #FFFFFF;
    font-size: 1.6rem;
    font-family: moskextra-bold_800;
    height: 1.5rem;

`