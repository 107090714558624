import React from 'react';
import Transaction from '../../interfaces/Transaction';
import CurrencyText from '../CurrencyText';
import { TransactionItemDiv, TransactionSingleDetailBox, TransactionTitleSingleDetail, TransactionDescriptionSingleDetail } from './styles';

interface TransactionItemProps {
    transaction: Transaction
}

const TransactionItem: React.FC<TransactionItemProps> = ({
    transaction
}) => {
    return (
        <TransactionItemDiv>
            <TransactionSingleDetailBox>
                <TransactionTitleSingleDetail>Transação</TransactionTitleSingleDetail>
                <TransactionDescriptionSingleDetail>#{transaction.id}</TransactionDescriptionSingleDetail>
            </TransactionSingleDetailBox>
            <TransactionSingleDetailBox>
                <TransactionTitleSingleDetail>Valor</TransactionTitleSingleDetail>
                <TransactionDescriptionSingleDetail><CurrencyText value={transaction.amount / 100} /></TransactionDescriptionSingleDetail>
            </TransactionSingleDetailBox>
            <TransactionSingleDetailBox>
                <TransactionTitleSingleDetail>Cartão</TransactionTitleSingleDetail>
                <TransactionDescriptionSingleDetail>**** **** {transaction.card_last_digits}</TransactionDescriptionSingleDetail>
            </TransactionSingleDetailBox>
            <TransactionSingleDetailBox>
                <TransactionTitleSingleDetail>Parcelas</TransactionTitleSingleDetail>
                <TransactionDescriptionSingleDetail>em {transaction.installments}x</TransactionDescriptionSingleDetail>
            </TransactionSingleDetailBox>
            <TransactionSingleDetailBox>
                <TransactionTitleSingleDetail>Status</TransactionTitleSingleDetail>
                <TransactionDescriptionSingleDetail>
                {
                    transaction.status === 'paid' ?
                        `PAGO`
                    : transaction.status === 'refused' ?
                        `RECUSADO`
                    :
                        transaction.status
                }
                </TransactionDescriptionSingleDetail>
            </TransactionSingleDetailBox>
        </TransactionItemDiv>
    )
}

export default TransactionItem;