import styled from "styled-components"

export {
    Pagination,
    ItemPagination
}

const Pagination = styled.ul`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ItemPagination = styled.li`
    list-style: none;
    margin: 0 0.5rem;
    font-size: 1.2rem;
    font-family: moskextra-bold_800;
    cursor: pointer;

    color: ${({className}): any => className === "active" ? `var(--color-primary)` : `var(--color-secondary)` };
`