import styled from 'styled-components';

export {
    DivProductDetail
}

const DivProductDetail = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;
    div {
        width: 33%;
    }
`