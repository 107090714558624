import React, { createContext, useState, useEffect, useContext } from 'react'
import * as auth from '../services/auth';
import IUser from '../interfaces/User';
import api from '../services/api';
import Profile from '../interfaces/Profile';

interface AuthContextData {
    signed: boolean;
    user: IUser | null;
    signIn(email: string, password: string): Promise<void>;
    signOut(): Promise<void>;
    signInFacebook(user: any): Promise<void>;
    sellerProfile: Profile | undefined;
    buyerProfile: Profile | undefined;
    loadMe: () => Promise<void>

}
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider : React.FC = ({ children }) => {
    const [user, setUser] = useState<IUser | null>(null);

    useEffect(() => {
        const storageUser = localStorage.getItem("@VenatuAuth:user");
        const storageToken = localStorage.getItem("@VenatuAuth:token");

        if (storageUser && storageToken) {
            api.defaults.headers.Authorization = `Bearer ${storageToken}`;
            setUser(JSON.parse(storageUser))
        }
    }, [])

    async function signIn(email: string, password: string) {
        const { data } = await auth.signIn(email, password);
        const { token, user } = data;
        api.defaults.headers['Authorization'] = `Bearer ${token}`;
        localStorage.setItem("@VenatuAuth:user", JSON.stringify(user));
        localStorage.setItem("@VenatuAuth:token", token);
        setUser(user);
    }

    async function signInFacebook(fbUser: any) {
        const { data } = await auth.signInWithFacebook(fbUser);
        const { token, user } = data;
        api.defaults.headers['Authorization'] = `Bearer ${token}`;
        localStorage.setItem("@VenatuAuth:user", JSON.stringify(user));
        localStorage.setItem("@VenatuAuth:token", token);
        setUser(user);
    }

    async function signOut() {
        try {
            auth.signOut();
        } catch (error) { }
        setUser(null);
        api.defaults.headers.Authorization = '';
        localStorage.removeItem('@VenatuAuth:user')
        localStorage.removeItem('@VenatuAuth:token')
    }

    async function loadMe() {
        const { data } = await auth.loadMe();
        const { token, user } = data;
        api.defaults.headers['Authorization'] = `Bearer ${token}`;
        localStorage.setItem("@VenatuAuth:user", JSON.stringify(user));
        localStorage.setItem("@VenatuAuth:token", token);
        setUser(user);
    }

    return (
        <AuthContext.Provider value={{ 
            signed: !!user, 
            user: user, 
            signIn, 
            signOut, 
            signInFacebook, 
            sellerProfile: user?.profiles?.find(prof => prof.role === 'SELLER'),
            buyerProfile: user?.profiles?.find(prof => prof.role === 'CUSTOMER'),
            loadMe
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext);

    return context;
};