import styled, { keyframes } from 'styled-components';


const rotate = keyframes`
    from {
        transform: translate(0, 0) rotate(0deg);
    }
    to {
        transform: translate(0, 0) rotate(360deg);
    }
`

export const Rotate = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    backface-visibility: hidden;
    transform-origin: 0 0;
`

export const Loading = styled.div`
    margin: 0 auto;
    width: 42.12px;
    height: 42.12px;
    border: 4.68px solid #ff9000;
    border-top-color: transparent;
    border-radius: 50%;

    box-sizing: content-box;

    animation: ${rotate} 0.6s linear infinite;
`