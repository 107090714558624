import React from 'react';
import { Container } from './styles';

interface ProductStateProps {
    state: "NEW" | "USED"
}

const ProductState: React.FC<ProductStateProps> = ({
    state
}) => {
  return  (
    <Container>
        {state === 'USED' ? 'USADO' : 'NOVO'}
    </Container>)
}

export default ProductState;