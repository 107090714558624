import { getAddressByCEP } from '../correios';

export interface AddressProps {
    zipcode: string,
    street: string,
    complement: string,
    neighbor: string,
    uf: string,
    city: string
}

export const getAddressInfoByCep = async (cep: string): Promise<AddressProps | undefined>  => {
    try {
        const {
            logradouro, complemento, bairro, localidade, uf
        } = await getAddressByCEP(cep);

        const serialized: AddressProps = {
            zipcode: cep,
            street: logradouro,
            complement: complemento,
            neighbor: bairro,
            uf,
            city: localidade
        }

        return serialized;
    } catch (error) {
        console.log("Error on getAddressInfo by cep");
    }
    return undefined;
}