import React from 'react';
import Auction from '../../interfaces/Auction';

import { Container, TitleRow, HourLabel, CurrentPriceLabel, LastBidLabel } from './styles';
import BidsRow from './BidsRow';
import CurrencyText from '../CurrencyText';
import { formatDate } from '../../util/formatDate';
import Bid from '../../interfaces/Bid';
import Offer from '../../interfaces/Offer';

interface BidsTableProps {
    bids: Bid[],
    auction: Auction,
    offer?: Offer
}

const BidsTable: React.FC<BidsTableProps> = ({
    bids,
    auction,
    offer
}) => {

    return (
    <Container>
        <TitleRow>
            <HourLabel>Horário</HourLabel>
            <CurrentPriceLabel>Valor atual</CurrentPriceLabel>
            <LastBidLabel>Últimos lançes</LastBidLabel>
            </TitleRow>
            <TitleRow>
            <HourLabel>{formatDate(auction.created_at)}</HourLabel>
            <CurrentPriceLabel><CurrencyText value={auction.initialBid} /></CurrentPriceLabel>
            <LastBidLabel><CurrencyText value={0} /></LastBidLabel>
        </TitleRow>

            {
            bids.length > 0 &&
                bids.map(bid => (
                    <BidsRow offer={offer} bid={bid} key={String(bid.id)} />
                ))
            }
    </Container>
  )
}

export default BidsTable;