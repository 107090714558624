import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainContainer, MainContent } from '../../assets/GlobalStyles';
import GifLoading from '../../components/GifLoading';
import OfferBox from '../../components/OfferBox';
import SimpleProductView from '../../components/SimpleProductView';
import Auction from '../../interfaces/Auction';
import { getOne } from '../../services/auction';
import { Column } from '../ProductRegistration/style';

import { AuctionSection, OfferSection, Avatar, SimpleUserView } from './styles'

import defaultAvatar from '../../assets/images/default-avatar.jpg';

interface AuctionParams {
    id: string,
}

const AuctionDetail = () => {
    const { id } = useParams<AuctionParams>();
    const [auction, setAuction] = useState({ } as Auction)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        initAuction()
    }, [])

    async function initAuction () {
        setLoading(true);
        try {
            const data = await getOne(Number(id));
            data.product.state = data.state;
            setAuction(data);
        } catch (error) {
            console.log("Error")
        }
        setLoading(false);
    }

    return (
        <MainContainer>
            <MainContent>
                {loading ? <GifLoading /> : 
                <AuctionSection>
                    <SimpleProductView product={auction.product} price={auction.initialBid} />
                    <SimpleUserView>
                        {
                            auction.buyer.avatar ? 
                                <Avatar src={auction.buyer.avatar} />
                            :
                                <Avatar src={defaultAvatar} />
                        }
                        <Column>
                            <strong>Interessado de {auction.from}</strong>
                            <p>Duração do leilão: {auction.duration / 60}h</p>
                        </Column>
                    </SimpleUserView>
                </AuctionSection>}
                {loading ? <GifLoading /> : 
                <OfferSection>
                    <OfferBox auction={auction} />
                </OfferSection>}
            </MainContent>
        </MainContainer>
  );
}

export default AuctionDetail;