import styled from 'styled-components'

export {
    CustomModalContainer,
    CustomModalContent,
    Title,
    Message,
    CustomModalFooter,
    CustomModalBody,
    CustomModalHeader
}



const CustomModalContainer = styled.div`
    display:flex;
    align-self: center;     
    height: 25rem;
    width: 35.9375rem;
    overflow: hidden;   
    flex-direction: column;
    box-shadow: var(--color-gray) 0px 1px 1px;
    position: absolute;
    z-index:51;
    
`
const CustomModalContent = styled.div``

const CustomModalHeader = styled.header`
    display: flex;
    width:100%;
    background-color: #F2F2F2;
    height: 4.375rem;
    
`

const CustomModalFooter = styled.footer`
    display: flex;
    width:100%;
    background-color: #F2F2F2;
    height: 4.375rem;
    flex-direction: row;
    flex-wrap: wrap;
    padding:1rem;
    align-items:center;
    justify-content: flex-end;
`

const CustomModalBody = styled.div`
    display: flex;
    width:100%;
    height: 16.25rem;
    background-color: #fff;
    flex-direction: column;
`


const Title = styled.p`
    color: var(--color-strong-gray);
    text-align: center;
    font-family: var(--font-secondary-bold);
    height: 2.125rem;
    padding-left: 1.875rem;
    padding-top: 1.875rem;
`

const Message = styled.p`
    font-family: var(--font-primary);
    color: var(--color-strong-grey);
`