import React from 'react';
import Routes from './routes';
import { GlobalStyles } from './assets/GlobalStyles';
import { AuthProvider } from './contexts/auth';

function App() {
  return (
    <AuthProvider>
      <GlobalStyles />
      <Routes />
    </AuthProvider>    
  );
}

export default App;
