export function buildFormData(formData: FormData, data: any, parentKey: any = undefined) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else if (data instanceof Date) {
        const value = data == null ? '' : data;
        formData.append(parentKey, String(value));
    } else {
        const value = data == null ? '' : String(data);
        formData.set(parentKey, value);
    }
}