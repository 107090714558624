import Purchase from '../../interfaces/Purchase';
import { CardFields } from '../../pages/PurchaseAuction';
import api from '../api';

export const checkout = async (purchaseId: number, card: CardFields & Purchase) => {
    try {
        const { data } = await api.post(`purchase/${purchaseId}/checkout`, card)
        return data;
    } catch (error) {
        console.log("error on checkout")
        console.log(error);
        throw error;
    }
}