import React from 'react';
import ModuleFacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import styled from 'styled-components';
import { useAuth } from '../../contexts/auth';
import { useHistory, useLocation } from 'react-router-dom';

const ContainerFacebookLoginButton = styled.a`
    width: 100%;
    background-color: #37549A;
    color: #fff;
    border-radius: 0.7rem;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    height: 2.6rem;

    display: flex;
    justify-content: center;
    align-items: center;

    span {
        width: 100%;
        position: relative;
    }
    .metro {
        position: relative;
        background-color: transparent;
        border: 0;
        color: #fff;
        width: 100%;
        height: 2.6rem;
        cursor: pointer;
    }
`


const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_APP_ID || '';

interface CustomReactFacebookLoginInfo extends ReactFacebookLoginInfo {
    birthday: string,
    gender: string
}

const BtnFacebook = () => {

    const {  signInFacebook } = useAuth();
    const history = useHistory();
    const { state } = useLocation<{ returnUrl: string }>();

    function validateFacebookResponse(response: any) {
        const { error } = response;
        if (error) {
            alert('Erro de login. Tente novamente.')
            return false;
        }
        const { name, email } = response;
        if (!name || !email) {
            alert('Nome ou e-mail não recuperados')
            return false;
        }

        return true;
    }

    async function handleFacebookResponse(response: CustomReactFacebookLoginInfo) {
        if(!validateFacebookResponse(response)) {
            alert('Erro nos dados do Facebook, tente novamente.')
            return;
        };

        const { email, name, id, picture
            //, birthday, gender 
        } = response;

        //const birthFormatted = birthday  && new Date(birthday).toLocaleDateString("pt-BR")

        const user = {
            email,
            name,
            facebookId: id,
            avatar: picture?.data.url,
            // birthday: birthFormatted,
            // gender: gender === "male" ? 'MASCULINO' : 'FEMININO'
        }

        try {
            await signInFacebook(user);
            if (state && state.returnUrl) {
                history.push(state.returnUrl);
            } else {
                history.push('/');
            }
        } catch (error) {
            history.push("/register", user)
        }
    }

    return (
        <ContainerFacebookLoginButton>
            <ModuleFacebookLogin
                language="pt-BR"
                appId={FACEBOOK_ID}
                callback={handleFacebookResponse}
                fields="name,email,picture,gender,birthday"
                // scope="public_profile,user_gender,user_birthday"
                scope="public_profile"
                icon="fa-facebook"
                redirectUri="/"
                onFailure={() => alert('Algo deu errado, tente novamente.')}
                textButton="acebook"
                cssClass=""
            >
                
            </ModuleFacebookLogin>
        </ContainerFacebookLoginButton>
        
    )
}

export default BtnFacebook;